import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { CameraOff as CameraOffIcon } from 'react-feather';

const useStyles = makeStyles(theme => ({
  noImageSelected: {
    flex: 1,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    border: '3px dashed #d1d1d1'
  },

  noImageIcon: {
    height: '32px',
    width: '32px'
  }
}));

function NoImageFound({ height, width, label = 'Nenhuma imagem selecionada' }) {
  const classes = useStyles();
  return (
    <Box
      height={height && height}
      width={width && width}
      className={classes.noImageSelected}
    >
      <CameraOffIcon className={classes.noImageIcon} />
      <Typography align="center">{label}</Typography>
    </Box>
  );
}

export default NoImageFound;