import { Backdrop, Fade, Modal as ReactModal } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';

const Modal = ({
  open = false,
  width,
  height,
  bg,
  children,
  style
}) => {
  const classes = useStyles();
  return (
    <>
      {open && (
        <ReactModal
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={open}>
            <div
              className={classes.paper}
              style={{
                ...style,
                width: width ? width : 'inherit',
                height: height ? height : 'inherit',
                background: bg
              }}
            >
              {children}
            </div>
          </Fade>
        </ReactModal>
      )}
    </>
  );
};

export default Modal;