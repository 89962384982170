import React from 'react';

import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Lottie from 'react-lottie';
import animationData from '../../assets/login.json';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backdrop: {
    zIndex: 9999,
    color: '#8FC18A',
    background: 'rgba(0,0,0,0.1)'
  }
});

const Loading = props => {
  const classes = useStyles();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Backdrop className={classes.backdrop} open={props.loading}>
      <Lottie 
	      options={defaultOptions}
        height={300}
        width={300}
      />
    </Backdrop>
  );
};

export default Loading;