import http from "./../../services/api";
import store from "../index";
import * as types from "./../types/empresas";

export const loadempresa = filtro => {
  return dispatch => {
    dispatch({ type: types.LOADING_EMPRESAS, payload: true });
    http.get(`/api/v1/empresa/list`).then(
      response => {
        const data = response.data    
        dispatch([
          { type: types.SET_EMPRESAS, payload: data.empresas},
          // { type: types.LOAD_empresa_PAGE, payload: parseInt(page) },
          // { type: types.LOAD_empresa_PERPAGE, payload: parseInt(per_page)},
          // { type: types.LOAD_empresa_TOTAL, payload: parseInt(total) },
          { type: types.LOADING_EMPRESAS, payload: false }
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_EMPRESAS, payload: false });
      }
    );
  };
};



export function inserirempresa(empresa ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_EMPRESAS, payload: true });
    http.post(`/api/v1/empresa/insert`, empresa)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_EMPRESAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_EMPRESAS, payload: false });
          reject();
        }
      );
  });
}

export function editarempresa(empresa, id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_EMPRESAS, payload: true });
    http.post(`/api/v1/empresa/edit/${id}`, empresa)
      .then(
        ({ data }) => {          
          store.dispatch({ type: types.LOADING_EMPRESAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_EMPRESAS, payload: false });
          reject();
        }
      );
  });
}

export function disabledempresa(id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_EMPRESAS, payload: true });
    http.put(`/api/v1/empresa/disable/${id}`,)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_EMPRESAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_EMPRESAS, payload: false });
          reject();
        }
      );
  });
}