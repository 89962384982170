import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  prontuarios: {
    width: "100%",
    height: "100vh",

  },
  prontuariosContainer: {
    boxShadow: "0px 0px 15px 0px #e3e3e3",
    borderRadius: '7px',
    backgroundColor: "#fff",
    width: "70%",
    margin: "auto",
    marginTop: "2em",
    padding: '25px',
    '& h1':{
        fontSize: '22px',
        fontWeight: 'normal'
    }
  },
  center:{
    alignItems: "center",
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  btnEdit: {
    backgroundColor: '#000041', 
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    padding: '5px 15px',
    cursor: 'pointer'
  }
}));

export default useStyles;
