import * as types from './../types/enderecos';

const INITIAL_STATE = {
  loading: false,
  enderecos: [],

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ENDERECOS:
      return { ...state, enderecos: action.payload };

    case types.LOADING_ENDERECOS:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};