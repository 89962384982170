import React, { useEffect, useRef, useState } from "react";

import { Divider, Grid } from "@material-ui/core";
import dayjs from "dayjs";
import { BsPeople } from "react-icons/bs";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { DataTable, InputDate, InputSelect, InputText } from "../../components/index";
import Modal from "../../components/modal";
import { loadempresa } from "../../store/actions/empresas";
import {
    disabledFuncionario,
    inserirFuncionario,
    loadFuncionarios
} from "../../store/actions/funcionarios";
import {
    editarVinculos,
    loadVinculos
} from "../../store/actions/vinculo_pessoa_empresa";
import ChooseConfig from "./components";
import useStyles from "./styles.jsx";

function VinculoPessoaEmpresa() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { vinculo_pessoa_empresa } = useSelector(
    (state) => state.vinculo_pessoa_empresa
  );

  const [dados, setDados] = useState([]);
  const [open, setOpen] = useState(false);

  const [paginacao, setPaginacao] = useState({
    perpage: 10,
    total: 0,
    page: 0,
  });
  const [openDocsUpload, setOpenDocsUpload] = useState(false);
  const [open_edit, setOpenEdit] = useState(false);
  const [modal_edit, setModalEdit] = useState("");
  const [modal_insert, setModalInsert] = useState({ nome: "", cpf_cnpj: "" });

  const [selectData, setSelectData] = useState({
    status_operacional: "",
    data_admissao: "",
    data_termino: "",
    matricula: ""
  });

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  const OpenDocs = () => {
    setOpenDocsUpload(openDocsUpload ? false : true);
  };
  const CloseDocs = () => {
    setOpenDocsUpload(openDocsUpload === false);
  };

  const fileInputRef = useRef();

  useEffect(() => {
    dispatch([loadVinculos(), loadempresa(), loadFuncionarios()]);
  }, []);

  const handleChange = () => {
    // do something with event data
  };

  function OpenEditModal(data) {
    setOpenEdit(true);
    setModalEdit(data.id);
    setSelectData({
      ...selectData,
      status_operacional: data.status_operacional,
      data_admissao: data.inicio_contrato,
      data_termino: data.fim_contrato,
      matricula: data.matricula,
    });
  }

  function CloseInsertModal() {
    setModalInsert({ nome: "", cpf_cnpj: "" });
    Close();
  }

  async function OnSubmitFunctionario() {
    try {
      await inserirFuncionario(modal_insert);
      dispatch(loadFuncionarios());
      CloseInsertModal();
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }

  async function DisabledFuncionario(id) {
    try {
      await disabledFuncionario(id);
      dispatch(loadFuncionarios());
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }

  const validacao_fim_contrato =
    selectData.status_operacional == "A" ? null : selectData.data_termino;

  async function OnSubmitEditFunctionario(id) {
    try {
      await editarVinculos(modal_edit, {
        status_operacional: selectData.status_operacional,
        inicio_contrato: selectData.data_admissao,
        fim_contrato: validacao_fim_contrato,
        matricula: selectData.matricula,
      });
      dispatch(loadVinculos());
      setOpenEdit(false);
      setSelectData({ status_operacional: "A" });
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }

  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Vínculo de Pessoas e Empresas:
        </h4>
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item sm={8} xs={8}>
            {/* <InputDebounce
                  label="Digite o nome ou CPF para pesquisar"
                  value={dados.pesquisa}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => console.log("filtro")}
                /> */}
          </Grid>
          <Grid item sm={4} xs={4}>
            <div style={{ float: "right", marginRight: "1em" }}>
              <button
                onClick={Open}
                style={{
                  border: "none",
                  padding: "12px 15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#000041",
                  color: "#fff",
                  cursor: "pointer",
                  borderRadius: "5px",
                  marginTop: "0.9em",
                  fontSize: "10px",
                }}
              >
                <BsPeople style={{ fontSize: "16px" }} />
                &nbsp; GERAR VÍNCULO
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid sm={12} style={{ marginTop: "2em" }}>
          <PerfectScrollBar style={{ fontSize: "15px" }}>
            <DataTable
              columns={[
                { title: "Funcionário", field: "nome",   cellStyle: { width: "30%" }, },
                { title: "Matrícula", field: "matricula" },
                {
                  title: "Admissão",
                  cellStyle: {  textAlign: "center" },
                  render: (row) =>
                    dayjs(row.inicio_contrato).format("DD/MM/YYYY"),
                },
                {
                  title: "Recisão",
                  cellStyle: {  textAlign: "center", width: "15%" },
                  render: (row) =>
                    row.fim_contrato
                      ? dayjs(row.fim_contrato).format("DD/MM/YYYY")
                      : "",
                },
                {
                  title: "Status",
                  cellStyle: {  textAlign: "center" },
                  render: (row) =>
                    row.status_operacional == "A" ? "Ativo" : "Desligado",
                },
                {
                  title: "Ações",
                  cellStyle: { width: "15%", textAlign: "center" },
                  render: (row) => {
                    return (
                      <>
                        <button
                          style={{
                            cursor: "pointer",
                            background: "transparent",
                            border: "none",
                          }}
                          onClick={() => OpenEditModal(row)}
                        >
                          {/* <EditOutlined style={{ width: "16px" }} />{" "} */}
                          Editar
                        </button>
                        &nbsp;
                      </>
                    );
                  },
                },
              ]}
              data={vinculo_pessoa_empresa}
            />

            {/* <TablePaginate
            style={{overflowX: 'hidden', }}
            rowsPerPageOptions={[10, 20, 30]}
            rowsPerPage={+rows}
            count={+total}
            page={+page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          /> */}
            {/* <DataGridTable
                  columns={[
                    // {title: "Criado em:", cellStyle:{ width: '15%', textAlign: "center"}, render: (row) => dayjs(row.created_at).format('DD/MM/YYYY') },
                   
                    {title: "Funcionário", 
                      render: (row) =>  funcionarios && funcionarios.find((i) => i.id == row.funcionario_id) ? 
                       <Tooltip title={funcionarios && funcionarios.find((i) => i.id == row.funcionario_id).nome} >
                      <div style={{maxWidth: "30ch", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                      {funcionarios && funcionarios.find((i) => i.id == row.funcionario_id).nome}
                      </div>
                      </Tooltip>

                       :
                        row.funcionario_id },
                    {title: "Matrícula", field: "matricula" },
                   {title: "Admissão", cellStyle:{ width: '15%', textAlign: "center"}, render: (row) => dayjs(row.inicio_contrato).format('DD/MM/YYYY') },
                   {title: "Fim do Contrato", cellStyle:{ width: '30%', textAlign: "center"}, render: (row) =>  row.fim_contrato ?  dayjs(row.fim_contrato).format('DD/MM/YYYY') : '' },
                    {title: "Status", cellStyle:{ width: '10%', textAlign: "center"},
                    render: (row) => row.status_operacional == 'A' ? 'Ativo'  : 'Desligado'
                  },
                  {
                    title: "Ações",
                    cellStyle: { width: "15%", textAlign: "center" },
                    render: (row) => {
                      return (
                        <>
                          <Tooltip title="Editar Funcionário">
                            <button
                              style={{
                                cursor: "pointer",
                                background: "transparent",
                                border: "none",
                              }}
                              onClick={() => OpenEditModal(row)}
                            >
                              <EditOutlined style={{ width: "16px" }} />{" "}
                            </button>
                          </Tooltip>{" "}
                          &nbsp;                       
                        </>
                      );
                    },
                  },
                  ]}
                  data={vinculo_pessoa_empresa}
                  options={{
                    headerStyle: {
                      backgroundColor: "#F2F2F3",
                      fontSize: 12,
                      textAlign: "center",
                      borderLeft: "solid 0.5px white",
                    },
                  
                  }}
                  // onRowClick={(event, rowData) => navigate(`${rowData.id_transacao}`)}
                /> */}
            {/* <TablePaginate
                  rowsPerPageOptions={[10, 30, 50, 100]}
                  rowsPerPage={parseInt(perpage)}
                  count={parseInt(total)}
                  page={parseInt(page) - 1}
                  onChangePage={(e, newPage) => handleChangePage(newPage)}
                  onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
                /> */}
          </PerfectScrollBar>
        </Grid>

        <Modal
          open={open}
          width="50%"
          style={{
            padding: "20px 30px",
            width: "50%",
            maxHeight: "70%",
            overflowY: "scroll",
          }}
        >
          <h6 style={{ margin: 0 }}>
            Selecione as informações para gerar um vínculo
          </h6>
          <Divider />
          <ChooseConfig close={() => setOpen(false)} />
          <Divider />
          <div style={{ float: "right" }}>
            {/* <button onClick={() => OnSubmitEditFunctionario(modal_edit.id)} 
                 style={{
                  border: '1px solid #3CA011', 
                  background: "transparent", 
                  padding: '7px 15px ', 
                  borderRadius: '4px',
                  color: "#3CA011",
                  cursor: "pointer"
                  }}
                > Editar</button> &nbsp; */}
            <button
              onClick={() => setOpen(false)}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
             
              Fechar
            </button>
          </div>
        </Modal>

        <Modal open={open_edit} style={{ padding: "20px 30px", width: "40%" }}>
          <h6 style={{ margin: 0 }}>
            Insira as informações para editar o funcionário
          </h6>
          <Divider />
          <Grid item xs={12} sm={12}>
            <InputSelect
              name="status"
              label="Selecione o Status"
              values={[
                { value: "A", label: "Ativo" },
                { value: "D", label: "Desligado" },
              ]}
              value={selectData.status_operacional}
              onChange={(e) =>
                setSelectData({
                  ...selectData,
                  status_operacional: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputText
              name="Matricula"
              label="Matricula"
              value={selectData.matricula}
              onChange={(e) =>
                setSelectData({
                  ...selectData,
                  matricula: e.target.value,
                })
              }
            />
          </Grid>
          {selectData.status_operacional == "A" ? (
            <Grid item xs={12} sm={12}>
              <InputDate
                name="data_admissao"
                fullWidth
                label="Data de Admissão"
                value={dayjs(selectData.data_admissao).format("YYYY-MM-DD")}
                onChange={(d) =>
                  setSelectData({ ...selectData, data_admissao: d })
                }
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={12}>
                <InputDate
                  name="data_admissao"
                  fullWidth
                  label="Data de Admissão"
                  value={dayjs(selectData.data_admissao).format("YYYY-MM-DD")}
                  onChange={(d) =>
                    setSelectData({ ...selectData, data_admissao: d })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputDate
                  name="data_termino"
                  fullWidth
                  label="Término do Contrato"
                  value={dayjs(selectData.data_termino).format("YYYY-MM-DD")}
                  onChange={(d) =>
                    setSelectData({ ...selectData, data_termino: d })
                  }
                />
              </Grid>
            </>
          )}
          <Divider />
          <div style={{ float: "right" }}>
            <button
              onClick={() => OnSubmitEditFunctionario(modal_edit)}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
             
              Editar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => setOpenEdit(false)}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
             
              Fechar
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default VinculoPessoaEmpresa;
