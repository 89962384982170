import { combineReducers, createStore, applyMiddleware } from 'redux';
import multi from 'redux-multi';
import thunk from 'redux-thunk';

import { usuario, home, funcionarios, empresas, categoria_arquivo, vinculo_pessoa_empresa, tipo_de_arquivos, enderecos, pastas, arquivos } from './reducers'

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const reducers = combineReducers({
    usuario, home, funcionarios, empresas, categoria_arquivo, vinculo_pessoa_empresa, tipo_de_arquivos, enderecos, pastas, arquivos
});

export default applyMiddleware(thunk, multi)(createStore)(
    reducers,
    process.env.NODE_ENV === 'development' ? devTools : undefined
);