import { Grid } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { DataGridTable, InputDate, InputSelect } from "../../../components";
import { formatCNPJ, formatCPF } from "../../../Helpers";
import { loadempresa } from "../../../store/actions/empresas";
import { loadFuncionarios } from "../../../store/actions/funcionarios";
import {
    inserirVinculos,
    loadVinculos
} from "../../../store/actions/vinculo_pessoa_empresa";
import useStyles from "../styles";

function ChooseConfig({ close }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { empresas } = useSelector((state) => state.empresas);
  const { funcionarios } = useSelector((state) => state.funcionarios);
  const [situacao, setSituacao] = useState({
    status: "A",
    data_admissao: "",
    data_termino:  ""
  });

  useEffect(() => {
    dispatch(loadempresa());
    dispatch(loadFuncionarios());
  }, []);

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const initialStep = () => {
    setStep(step - 3);
  };

  const [empresa_select, setEmpresaSelect] = useState({});
  const [pessoa_select, setPessoaSelect] = useState({});
  const [inicio_contrato, setInicioContrato] = useState({ data: "01/01/2010" });

  function SelectedCompany(data) {
    nextStep();
    setEmpresaSelect(data);
  }

  function SelectedPerson(data) {
    nextStep();
    setPessoaSelect(data);
  }

  async function ConfirmVinculo() {
    try {
      await inserirVinculos({
        empresa_id: empresa_select.id,
        funcionario_id: pessoa_select.id,
        inicio_contrato: situacao.data_admissao,
        fim_contrato: situacao.data_termino,
        status_operacional: situacao.status,
      });
      dispatch(loadVinculos());
      close();
      setSituacao("A")
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }

  switch (step) {
    case 1:
      return (
        <div className={classes.root}>
          <Grid sm={12} style={{ marginTop: "2em" }}>
            <PerfectScrollBar>
              <DataGridTable
                columns={[
                  {
                    title: "Razao Social",
                    field: "razao_social",
                    cellStyle: { width: "50%" },
                  },
                  {
                    title: "CNPJ",
                    cellStyle: { width: "30%" },
                    render: ({ cnpj }) =>
                      cnpj &&
                      (cnpj.length === 11 ? formatCPF(cnpj) : formatCNPJ(cnpj)),
                  },

                  {
                    title: "Selecionar",
                    cellStyle: { textAlign: "center" },
                    render: (row) => (
                      <>
                       
                        <button
                          onClick={() => SelectedCompany(row)}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            color: "#3ca011",
                          }}
                        >
                          <CheckCircleOutline />
                        </button>{" "}
                      </>
                    ),
                  },
                ]}
                data={empresas}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                  rowStyle: (rowData) => ({
                    fontSize: 14,
                    textAlign: "center",
                  }),
                }}
              />
              {/* <TablePaginate
            rowsPerPageOptions={[10, 30, 50, 100]}
            rowsPerPage={paginacao.perpage}
            count={paginacao.total}
            page={paginacao.page - 1}
            onChangePage={(event, newPage) => handleChangePage(newPage)}
            onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          /> */}
            </PerfectScrollBar>
          </Grid>

          <br />
        </div>
      );
    case 2:
      return (
        <div className={classes.root}>
          <br />
          Empresa Selecionada: <b>{empresa_select.razao_social}</b>
          <Grid sm={12} style={{ marginTop: "2em" }}>
            <PerfectScrollBar>
              <DataGridTable
                columns={[
                  {
                    title: "Funcionário",
                    field: "nome",
                    cellStyle: { width: "40%" },
                  },
                  {
                    title: "Funcionário",
                    field: "cpf_cnpj",
                    cellStyle: { width: "40%" },
                    render: ({ cpf_cnpj }) =>
                      cpf_cnpj &&
                      (cpf_cnpj.length === 11
                        ? formatCPF(cpf_cnpj)
                        : formatCNPJ(cpf_cnpj)),
                  },
                  {
                    title: "Selecionar",
                    cellStyle: { textAlign: "center" },
                    render: (row) => (
                      <>
                       
                        <button
                          onClick={() => SelectedPerson(row)}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            color: "#3ca011",
                          }}
                        >
                          <CheckCircleOutline />
                        </button>{" "}
                      </>
                    ),
                  },
                ]}
                data={funcionarios.data}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                  rowStyle: (rowData) => ({
                    fontSize: 14,
                    textAlign: "center",
                  }),
                }}
              />
              {/* <TablePaginate
            rowsPerPageOptions={[10, 30, 50, 100]}
            rowsPerPage={paginacao.perpage}
            count={paginacao.total}
            page={paginacao.page - 1}
            onChangePage={(event, newPage) => handleChangePage(newPage)}
            onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          /> */}
            </PerfectScrollBar>
          </Grid>
          <br />
        </div>
      );
    case 3:
      return (
        <div className={classes.root}>
          <h5>Confirme as informações:</h5>
          <div>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                Empresa: {empresa_select.razao_social} <br />
              </Grid>
            
              <Grid item sm={12} xs={12}>
                Funcionário: {pessoa_select.nome}
              </Grid>
              <Grid item sm={4} xs={4}>
                <InputSelect
                  name="situacao"
                  label="Situacao do funcionário"
                  values={[
                    { value: "A", label: "Ativo" },
                    { value: "D", label: "Desligado" },
                  ]}
                  value={situacao.status}
                  onChange={(e) =>
                    setSituacao({ ...situacao, status: e.target.value })
                  }
                />
              </Grid>
                { situacao.status == "A" ? 
              <Grid item sm={4} xs={4}>
                  <InputDate
                  name="data_admissao"
                  fullWidth
                  label="Data de Admissão"
                  value={dayjs(situacao.data_admissao).format("YYYY-MM-DD")}
                  onChange={(d) =>
                    setSituacao({ ...situacao, data_admissao: d })
                  }
                />
                 </Grid>
                :
                <>
                     <Grid item sm={4} xs={4}>
                <InputDate
                  name="data_admissao"
                  fullWidth
                  label="Data de Admissão"
                  value={dayjs(situacao.data_admissao).format("YYYY-MM-DD")}
                  onChange={(d) =>
                    setSituacao({ ...situacao, data_admissao: d })
                  }
                />
                </Grid>
                <Grid item sm={4} xs={4}>
                
                  <InputDate
                name="data_termino"
                fullWidth
                label="Término do Contrato"
                value={dayjs(situacao.data_termino).format("YYYY-MM-DD")}
                onChange={(d) => setSituacao({ ...situacao, data_termino: d })
                }
              />
              </Grid>
                </>
              
              }
              
             
            </Grid>
          </div>
          <br />
          <div className={classes.btnConfirm}>
            <button onClick={() => ConfirmVinculo()}>CONFIRMAR VÍNCULO</button>
          </div>
          <br />
        </div>
      );
    default:
      return "";
  }
}

export default ChooseConfig;
