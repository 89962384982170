import * as types from './../types/vinculo_pessoa_empresa';

const INITIAL_STATE = {
  loading: false,

  vinculo_pessoa_empresa: [],
  funcionarioVinculado: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_VINCULO_PESSOA_EMPRESA:
      return { ...state, vinculo_pessoa_empresa: action.payload };

    case types.SET_FUNCIONARIO_VINCULADO_INFO:
      return { ...state, funcionarioVinculado: action.payload };

    case types.LOADING_VINCULOS:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};