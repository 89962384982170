import {
  Button, Collapse,
  List, ListItem, makeStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  matchPath, NavLink as RouterLink, useLocation,
  useNavigate
} from "react-router-dom";

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowRight
} from "@material-ui/icons";

import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    fontFamily: "Arial",
    overflow: "hidden",
  },
  link: {
    justifyContent: "flex-start",
    letterSpacing: 0,
    textTransform: "none",
    width: "100%",
    height: 30,
    fontWeight: "bold",
    color: "#817f82",
    "& svg": {
      fontSize: 20,
      marginRight: 8,
    },
  },
  subLink: {
    color: theme.palette.text.primary,
  },
  linkActive: {
    marginLeft: 1,
    color: "#262626",
    borderLeft: `4px solid #262626`,
    backgroundColor: "#fcfcfc",
  },
  collapse: {
    width: "100%",
  },
}));

const NavItem = ({
  disabled,
  href,
  icon: Icon,
  title,
  subitems = [],
  ...rest
}) => {
  const location = useLocation();
  const [subitemsVisible, setSubitemsVisible] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname
      )
    : false;

  const handleToggleChildren = (item) => {
    if (!item || item !== subitemsVisible) {
      setSubitemsVisible(item);
     
    } else {
      setSubitemsVisible(null);
    }
  };

  const [openMenu, setOpenMenu] = useState(false);

  function OpenListNav(){
    setOpenMenu(true)
    navigate('/app/categoria-arquivos');
    setDb('/app/categoria-arquivos')
  }

  const [db, setDb] = useState()



  function NavToLink(link){
    setDb(link)
    navigate(link);

  }



  return (
    <ListItem
      className={classes.item}
      disabled={disabled}
      disableGutters
      onClick={() => handleToggleChildren(title)}
      {...rest}
    >
      {subitems.length ? (
        <>
          <div style={{width: "100%", marginTop: "-0.7em"}} 
          >
            {openMenu ? (
              <button
              style={{ cursor: 'pointer',width: "100%", color: '#343d3f',border: 'none', background: "transparent", textDecoration: 'none',  alignItems: "center", display: "flex", marginTop: '0.5em',}}
                onClick={() => setOpenMenu(false)}
              >
                <Icon size="18" style={{marginLeft: "0.2em", color: '#262626'}} />
                <span style={{ fontWeight: "bold", color: "#817f82", fontFamily: "Roboto", fontSize: "14px", marginLeft: "1.2em"}}>{title}</span>
                <ExpandLessIcon style={{marginLeft: '3em'}} />
              </button>
            ) : (
              <button
              style={{ cursor: 'pointer',width: "100%", color: '#343d3f',border: 'none', background: "transparent", textDecoration: 'none',  alignItems: "center", display: "flex", marginTop: '0.5em',}}

              onClick={() =>  OpenListNav()}
              >
                  <Icon size="18" style={{marginLeft: "0.2em", color: '#817f82'}} />
                <span style={{ fontWeight: "bold", color: "#817f82", fontFamily: "Roboto", fontSize: "14px", marginLeft: "1.2em"}}>{title}</span>

                <ExpandMoreIcon style={{marginLeft: '3em'}} />
              </button>
            )}
          </div>
          {subitems.map((item) => (
            <Collapse
              in={openMenu}
              key={item.title}
              timeout="auto"
              unmountOnExit
              style={{ width: "100%" }}
            >
              <List>
                <button
                  style={{ cursor: 'pointer',width: "100%", color: '#343d3f',border: 'none', background: "transparent", textDecoration: 'none',  alignItems: "center", display: "flex", marginTop: '0.5em',}}
             
                  onClick={() => NavToLink(item.href)}
                >
                  <KeyboardArrowRight
                    style={{ fontSize: "14px", marginLeft: "1em" }}
                  />{" "}
                  <b
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      lineHeight: "0.5em",
                      fontWeight: db === item.href ? 'bold' : 'normal',
                      color: db === item.href ? '#4f4e4e' : '#817f82'
                    }}
                  >
                    {item.title}
                  </b>
                </button>
              </List>
            </Collapse>
          ))}
        </>
      ) : (
        <Button
          component={RouterLink}
          activeClassName={classes.linkActive}
          className={classes.link}
          to={href}
        >
          {Icon && <Icon size="20" />}
          <span style={{ marginLeft: 10 }}>{title}</span>
        </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
