import http from "./../../services/api";
import queryString from "query-string";
import store from "../index";
import * as types from "./../types/funcionarios";
import axios from 'axios';

export const loadFuncionarios = filtro => {
  return dispatch => {
    dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
    http.get(`/api/v1/funcionarios/list`).then(
      response => {
        const data = response.data
        dispatch([
          { type: types.LOAD_FUNCIONARIOS, payload: data},
          // { type: types.LOAD_FUNCIONARIOS_PAGE, payload: parseInt(page) },
          // { type: types.LOAD_FUNCIONARIOS_PERPAGE, payload: parseInt(per_page)},
          // { type: types.LOAD_FUNCIONARIOS_TOTAL, payload: parseInt(total) },
          { type: types.LOADING_FUNCIONARIOS, payload: false }
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
      }
    );
  };
};



export function inserirFuncionario(funcionario ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
    http.post(`/api/v1/funcionarios/insert`, funcionario)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
          reject();
        }
      );
  });
}

export function editarFuncionario(funcionario, id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
    http.post(`/api/v1/funcionarios/edit/${id}`, funcionario)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
          reject();
        }
      );
  });
}

export function disabledFuncionario(id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
    http.put(`/api/v1/funcionarios/disable/${id}`,)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
          reject();
        }
      );
  });
}

export const vincularFuncionarioEmpresa = (id, plano_id) => {
  return dispatch => {
    dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
    return http
      .put(`/api/v1/empresa/funcionarios/${id}/vincular`, { plano_id })
      .then(
        response => {
          dispatch([
            loadFuncionarios(),
            { type: types.LOADING_FUNCIONARIOS, payload: false }
          ]);
        },
        error => {
          dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
          console.log(error);
        }
      );
  };
};

export const atualizar = funcionario => {
  const { id, ...data } = funcionario;

  return dispatch => {
    dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
    return http.put(`/api/v1/empresa/funcionarios/update/${id}`, data).then(
      response => {
        dispatch([
          loadFuncionarios(),
          { type: types.LOADING_FUNCIONARIOS, payload: false }
        ]);
      },
      error => {
        dispatch({ type: types.LOADING_FUNCIONARIOS, payload: false });
        console.log(error);
      }
    );
  };
};

export const pesquisarFuncionarios = pesquisa => {
  return dispatch => {
    dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
    http.get(`/api/v1/empresa/funcionarios/cpf/${pesquisa}`).then(
      response => {
        dispatch([
          { type: types.LOAD_FUNCIONARIO, payload: response.data },
          { type: types.LOADING_FUNCIONARIOS, payload: false }
        ]);
      },
      error => {
        dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
        console.log(error);
      }
    );
  };
};

export const loadPlanos = () => {
  return dispatch => {
    dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
    http.get(`/api/v1/empresa/planos/listar`).then(
      response => {
        let newRet = response.data.map(row => ({
          label: row.nome,
          value: row.id
        }));
        dispatch([
          { type: types.LOAD_PLANOS, payload: newRet },
          { type: types.LOADING_FUNCIONARIOS, payload: false }
        ]);
      },
      error => {
        dispatch({ type: types.LOADING_FUNCIONARIOS, payload: true });
        console.log(error);
      }
    );
  };
};

export const consultPerson = cpf => {
  return new Promise((resolve, reject) => {
    http.get(`/api/v1/empresa/pacientes/get_pessoa?cpf=${cpf}`).then(
      resp => resolve(resp.data),
      err => reject(err)
    );
  });
};



export const consultCep = (cep, typeToSet) => {
  return dispatch => {
    axios.get(`http://viacep.com.br/ws/${cep}/json`).then(
      resp => {
        const ret = resp.data;
        dispatch([
          {
            type: typeToSet,
            payload: {
              cidade: ret.localidade,
              endereco: ret.logradouro,
              bairro: ret.bairro,
              uf: ret.uf
            }
          }
        ]);
      },
      error => {
        console.log(error);
        dispatch([{ type: typeToSet, payload: {} }]);
      }
    );
  };
};

export const inserirPaciente = dados => {
  return dispatch => {
    http.post(`/api/v1/empresa/pacientes/inserir`, dados).then(
      resp => {
        dispatch([{ type: types.LOADING_FUNCIONARIOS, payload: false }]);
      },
      error => {
        dispatch([{ type: types.LOADING_FUNCIONARIOS, payload: false }]);
      }
    );
  };
};

export const consultPersonInOwnDb = cpf => {
  return new Promise((resolve, reject) => {
    http.get(`/api/v1/empresa/funcionarios/paciente?cpf=${cpf}`).then(
      resp => resolve(resp.data),
      err => reject()
    );
  });
};

export const consultPersonInExtDb = cpf => {
  return new Promise((resolve, reject) => {
    http.get(`/api/v1/empresa/funcionarios/pessoa?cpf=${cpf}`).then(
      resp => resolve(resp.data),
      err => reject()
    );
  });
};

export const insertnewPatient = dados => {
  return dispatch => {
    http.post(`/api/v1/empresa/funcionarios/create`, dados).then(
      resp => {
        dispatch([
          loadFuncionarios(),
          {type: types.LOADING_FUNCIONARIOS, payload: false}
        ])
      },
      error => {
        dispatch([
          {type: types.LOADING_FUNCIONARIOS, payload: false}
        ])
      }
    )
  }
};

export const linkExistPatient = (dados) => {
  return dispatch => {
    http.post(`/api/v1/empresa/funcionarios/link`,dados).then(
      resp => {
        dispatch([
          loadFuncionarios(),
          {type: types.LOADING_FUNCIONARIOS, payload: false}
        ])
      },
      error => {
        dispatch([
          {type: types.LOADING_FUNCIONARIOS, payload: false}
        ])
      }
    )
  }
};

export const getFuncToEdit = (id) => {
  return dispatch => {
    http.get(`/api/v1/empresa/funcionarios/get_by_id?id=${id}`).then(
      resp => {
        dispatch([
          {type: types.FUNCIONARIO_EDIT, payload: {...resp.data}},
          {type: types.LOADING_FUNCIONARIOS, payload: false}
        ])
      },
      erro => {
        dispatch([
          {type: types.FUNCIONARIO_EDIT, payload: {razao_social: 'Não Identificado'}},
          {type: types.LOADING_FUNCIONARIOS, payload: false}
        ])
      }
    )
  }
}

export const desvincularFuncionario = (cliente_id) => {
  return dispatch => {
    http.put(`/api/v1/empresa/funcionarios/desvincular/${cliente_id}`).then(
      response => dispatch(loadFuncionarios()),
      error => console.log(error)
    );
  }
};