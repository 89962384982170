import * as types from './../types/funcionarios';

const INITIAL_STATE = {
  loading: false,

  funcionarios: [],

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOAD_FUNCIONARIOS:
      return { ...state, funcionarios: action.payload };

    case types.LOADING_FUNCIONARIOS:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};