import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import experimentalStyled from 'styled-components';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import {  makeStyles } from '@material-ui/core';
import { loadUsuario } from '../store/actions/usuario';
import { useDispatch, useSelector } from 'react-redux';
import * as types from './../store/types/usuario'

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: "#ffffff",
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const useStyles = makeStyles((theme) => ({
  DashboardLayoutWrapper:{
    display: 'flex',
    flex: "1 1 auto",
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles()
  useEffect(() => {
    if (!localStorage.getItem("id")) {
      navigate("/");
    }
  }, [navigate]);

  const { usuario } = useSelector(state => state.usuario);

 



  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.DashboardLayoutWrapper}>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </div>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;