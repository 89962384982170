import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',

    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },

    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  }
}));

const Page = forwardRef(({ children, title = '', ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
        <meta
          http-equiv="Content-Security-Policy"
          content="
            worker-src blob:; 
            child-src blob: gap:;
            img-src 'self' https: blob: data:;
            default-src * 'self' 'unsafe-inline' 'unsafe-eval' https: blop: data: gap: content:"
        />
      </Helmet>
      <div className={classes.root}>{children}</div>
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;