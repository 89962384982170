import * as types from "./../types/tipos_de_arquivos";

const INITIAL_STATE = {
  loading: false,
  tipo_de_arquivos: [],
  listaPorCategoria: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TIPO_ARQUIVO:
      return { ...state, tipo_de_arquivos: action.payload };

    case types.LOADING_TIPO_ARQUIVO:
      return { ...state, loading: action.payload };

    case types.SET_LISTA_POR_CATEGORIA:
      return { ...state, listaPorCategoria: action.payload };

    default:
      return state;
  }
};
