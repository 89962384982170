import React, { useState } from "react";
import useStyles from "./styles";
import {
  InputAdornment,
  TextField,
  Grid,
  MenuItem,
  Menu,
  Button,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";

import {
  User,
  Codepen,
  Codesandbox,
  Mail,
  Key,
  Lock,
  PlusCircle,
  Plus,
} from "react-feather";
import { GroupOutlined } from "@material-ui/icons";

function AddFuncionario() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.user}>
      <div className={classes.AddFuncionario}>
        <br />
        <br />
        <h1>Adicionar Funcionário: </h1>
        <br />
        <div className={classes.AddFuncionarioContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="input-with-icon-textfield"
                label="Nome"
                className={classes.text}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <User style={{ color: "#919191" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {"   "}
              <TextField
                id="input-with-icon-textfield"
                label="CPF"
                className={classes.text}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Codesandbox style={{ color: "#919191" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button className={classes.button}>
                <PlusCircle style={{ width: "14px" }} /> Adicionar
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default AddFuncionario;
