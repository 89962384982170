import {
    Divider, Table,
    TableBody,
    TableCell,
    TableContainer, TableHead, TableRow
} from "@material-ui/core";
import faker from "faker-br";
import React, { useEffect, useState } from "react";
import { XCircle } from "react-feather";
import Modal from './../../../components/modal/index';
import useStyles from "./styles";

function Logs() {
  const classes = useStyles();

  const [dados, setDados] = useState([]);

  const generate = () => {
    let item = [];
    for (let i = 0; i < 5; i++) {
      item.push(
        {
          id: faker.random.number(),
          name: faker.name.findName(),
          date: "19/09/2021 - 11:44:30",
          acao: "Editar",
        },
        {
          id: faker.random.number(),
          name: faker.name.findName(),
          date: "19/09/2021 - 10:25:07",
          acao: "Login",
        }
      );
      setDados(item);
    }
    item.sort((a, b) => (a.nome_razao < b.nome_razao ? -1 : 1));
  };

  const [open, setOpen] = useState(false);

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  useEffect(() => {
    generate();
  }, []);

  return (
    <>
      <div className={classes.Logs}>
        <div className={classes.LogsContainer}>
          <br />
          <br />
          <h1>
            <b>Log</b>: 19/09/2021
          </h1>

          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Usuario</TableCell>
                  <TableCell>Tipo de Ação</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dados.map((item) => {
                  return (
                    <>
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.acao}</TableCell>
                        <TableCell>{item.date}</TableCell>
                        <TableCell>
                          <button className={classes.btnEdit} onClick={() => Open()}>
                           
                            Visualizar
                          </button>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            style={{ padding: "20px 30px", width: "40%" }}
            title='Log de Atividade N°: 120'
            buttons={[
              {
                title: "Fechar",
                icon: <XCircle height="1.5em" width="1.5em" />,
                style: { color: "red", fontSize: "14px" },
                onClick: () => Close(),
              },
            ]}
          >
            <br />
            <b> Data</b>:   05/10/2021 - 22:22:22   <br />
            <b> Usuário</b>: Dario   <br />
            <b> Tipo de Ação:</b> Login   <br />
            <Divider />
            <p>Descrição</p>
            Logou no Sistema
            <br />
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Logs;
