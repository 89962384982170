import React, { useState } from "react";

import { debounce } from 'lodash';
import { TextField } from "@material-ui/core";



function InputDebounce({
  value,
  onChange,
  disabled = false,
  onKeyPress,
  InputProps,
  placeholder,
  style,
  label = "",
  debounceTime = 500,
}) {

  const [displayValue, setDisplayValue] = useState(value);
  const debounceChange = debounce(onChange, debounceTime);

  function handleChange(event) {
    setDisplayValue(event.target.value);
    debounceChange(event.target.value);
  }

  return (
    <TextField
      sx={{
        width: "100%",
        maxWidth: "100%",
        ...style,
      }}
      label={label}
      disabled={disabled}
      value={displayValue}
      onChange={handleChange}
      onKeyPress={onKeyPress}
      fullWidth
      InputProps={InputProps}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      variant="outlined"
      margin="dense"
    />
  );
}

export default InputDebounce;