import { Divider, Grid, Tooltip } from "@material-ui/core";
import { ArrowBack, CheckCircleOutline } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { XCircle } from "react-feather";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../../../components";
import { loadCategorias } from "../../../store/actions/categoria_arquivos";
import { loadempresa } from "../../../store/actions/empresas";
import { loadFuncionarios } from "../../../store/actions/funcionarios";
import {
    AvailableCategorys,
    inserirPastas,
    loadPastas
} from "../../../store/actions/pastas";
import { loadVinculos } from "../../../store/actions/vinculo_pessoa_empresa";
import useStyles from "../styles";

function CriarPasta({ open, close }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categorias } = useSelector((state) => state.categoria_arquivo);
  const { vinculo_pessoa_empresa } = useSelector(
    (state) => state.vinculo_pessoa_empresa
  );
  const { empresas } = useSelector((state) => state.empresas);
  const { funcionarios } = useSelector((state) => state.funcionarios);
  const [step, setStep] = useState(1);
  const [select_config, setSelectConfig] = useState({});
  const [select_collection, setSelectCollection] = useState({});
  const { rows, categoria_disponivel } = useSelector((state) => state.pastas);

  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
  const initialStep = () => {
    setStep(step - 3);
  };
  useEffect(() => {
    dispatch([
      loadCategorias(),
      loadVinculos(),
      loadempresa(),
      loadFuncionarios(),
    ]);
  }, []);

  async function SelectedCollection(data) {
    await dispatch(AvailableCategorys(data.id));
    setSelectCollection(data);
    nextStep();
  }

  function SelectedCompany(data) {
    setSelectConfig(data);
    nextStep();
  }

  //   function SubmitPasta() {
  //     console.log({
  //       categoria_id: select_config.id,
  //       collection_id: select_collection.id,
  //     });
  //   }

  async function SubmitPasta() {
    try {
      await inserirPastas({
        categoria_id: select_config.id,
        collection_id: select_collection.id,
      });
      dispatch(loadPastas({ page: Number(1), rows, status: "A" }));
      close();
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }

  switch (step) {
    case 1:
      return (
        <>
          <div style={{ padding: "1em" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={11}>
                <h4 style={{ margin: 0 }}>Selecione o funcionário</h4>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Tooltip title="Fechar">
                  <button
                    onClick={close}
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    <XCircle
                      style={{ color: "red", cursor: "pointer", width: "19px" }}
                    />
                  </button>
                </Tooltip>
              </Grid>
            </Grid>

            <Divider />
            <PerfectScrollBar>
              <DataTable
                columns={[
                  {
                    title: "Empresa",
                    cellStyle: { width: "40%" },
                    render: (row) =>
                      empresas.find((i) => i.id == row.empresa_id)
                        ? empresas.find((i) => i.id == row.empresa_id)
                            .razao_social
                        : row.empresa_id,
                  },
                  {
                    title: "Funcionario",
                    cellStyle: { width: "40%" },
                    render: (row) =>
                    funcionarios && funcionarios.find((i) => i.id == row.funcionario_id)
                        ? funcionarios.find((i) => i.id == row.funcionario_id)
                            .nome
                        : row.funcionario_id,
                  },
                  {
                    title: "Selecionar",
                    cellStyle: { textAlign: "center" },
                    render: (row) => (
                      <>
                       
                        <button
                          onClick={() => SelectedCollection(row)}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            color: "#3ca011",
                          }}
                        >
                          <CheckCircleOutline />
                        </button>{" "}
                      </>
                    ),
                  },
                ]}
                data={vinculo_pessoa_empresa}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                  rowStyle: (rowData) => ({
                    fontSize: 14,
                    textAlign: "center",
                  }),
                }}
              />
              {/* <TablePaginate
            rowsPerPageOptions={[10, 30, 50, 100]}
            rowsPerPage={paginacao.perpage}
            count={paginacao.total}
            page={paginacao.page - 1}
            onChangePage={(event, newPage) => handleChangePage(newPage)}
            onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
          /> */}
            </PerfectScrollBar>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={11}>
              Pasta: <b>{select_config.descricao}</b> <br />
            </Grid>
      
          </Grid>
          <Divider />

          <Grid sm={12} style={{  }}>
            <button
              onClick={() => setStep(1)}
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                color: "#3ca011",
                float: "right",
                display: "flex",
                alignContent: "center",
                alignItems: "center"
              }}
            >
              <ArrowBack style={{fontSize: "12"}} /> Voltar
            </button>
            <h5>Selecione a categoria da pasta:</h5>
      
            <Grid sm={12} style={{ marginTop: "2em" }}>
              <PerfectScrollBar>
                <DataTable
                  columns={[
                    {
                      title: "Descricao da Pasta",
                      field: "descricao",
                      cellStyle: { width: "70%" },
                    },
                    {
                      title: "Selecionar",
                      cellStyle: { textAlign: "center" },
                      render: (row) => (
                        <>
                         
                          <button
                            onClick={() => SelectedCompany(row)}
                            style={{
                              border: "none",
                              background: "transparent",
                              cursor: "pointer",
                              color: "#3ca011",
                            }}
                          >
                            <CheckCircleOutline />
                          </button>{" "}
                        </>
                      ),
                    },
                  ]}
                  data={categoria_disponivel}
                  options={{
                    headerStyle: {
                      backgroundColor: "#F2F2F3",
                      fontSize: 12,
                      textAlign: "center",
                      borderLeft: "solid 0.5px white",
                    },
                    rowStyle: (rowData) => ({
                      fontSize: 14,
                      textAlign: "center",
                    }),
                  }}
                />
                {/* <TablePaginate
rowsPerPageOptions={[10, 30, 50, 100]}
rowsPerPage={paginacao.perpage}
count={paginacao.total}
page={paginacao.page - 1}
onChangePage={(event, newPage) => handleChangePage(newPage)}
onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
/> */}
              </PerfectScrollBar>
            </Grid>
          </Grid>
        </>
      );
    case 3:
      return (
        <>
          Confirme as informações:
          <Divider style={{ marginTop: "0.5em", marginBottom: "0.5em" }} />
          <div style={{ textAlign: "center", lineHeight: "2em" }}>
            Pasta selecionada: <b>{select_config.descricao}</b> <br />
            Funcionário Selecionado:{" "}
            <b>
             
              {funcionarios && funcionarios.find(
                (i) => i.id == select_collection.funcionario_id
              )
                ? funcionarios.find(
                    (i) => i.id == select_collection.funcionario_id
                  ).nome
                : select_collection.funcionario_id}
            </b>
            <br />
            <button
              onClick={() => SubmitPasta()}
              style={{
                border: "1px solid #3ca011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3ca011",
                cursor: "pointer",
              }}
            >
             
              CRIAR PASTA
            </button>
          </div>
        </>
      );
    default:
      return "";
  }
}

export default CriarPasta;
