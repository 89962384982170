import store from "../index";
import http from "./../../services/api";
import * as types from "./../types/vinculo_pessoa_empresa";

export const loadVinculos = filtro => {
  return dispatch => {
    dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.get(`/api/v1/collections/list`).then(
      response => {
        const data = response.data
        dispatch([
          { type: types.SET_VINCULO_PESSOA_EMPRESA, payload: data},
          { type: types.LOADING_VINCULOS, payload: false }
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_VINCULOS, payload: false });
      }
    );
  };
};

export const loadFuncionarioVinculo = funcionarioId => {
  return dispatch => {
    dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.get(`/api/v1/collections/employer/${funcionarioId}`).then(
      response => {
        const data = response.data
        dispatch([
          { type: types.SET_FUNCIONARIO_VINCULADO_INFO, payload: data.data},
          { type: types.LOADING_VINCULOS, payload: false }
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_VINCULOS, payload: false });
      }
    );
  };
};


export function inserirVinculos(vinculos ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.post(`/api/v1/collections/create`, vinculos)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
          reject();
        }
      );
  });
}

export function editarVinculos(id ,dados ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.post(`/api/v1/collections/edit/${id}`, dados)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
          reject();
        }
      );
  });
}

export function disabledFuncionario(id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_VINCULOS, payload: true });
    http.put(`/api/v1/funcionarios/disable/${id}`,)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_VINCULOS, payload: false });
          reject();
        }
      );
  });
}
