import React from "react";

import { Box, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
function InputType({ open, children, title, subTitle, buttons, style, link,xs,sm }) {
  return (
    <Box>
      <Grid container spacing={1}>
        {title.map((item, index) => {
          return (
            <Grid item xs={xs} sm={sm}>
           
              <div style={{ width: "90%", margin: "auto" }}>
                <Link to={item.link} style={{ textDecoration: "none" }}>
                 
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #eaeaea",
                      borderRadius: "7px",
                      padding: "0.5em ",
                      color: "#010101",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={2}>
                        {item.icon}
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <b
                          style={{
                            fontSize: "13px",
                          }}
                        >
                          {item.nome}
                        </b>
                      </Grid>
                    </Grid>
                  </div>
                </Link>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default InputType;
