import axios from 'axios';

const baseUrl = () => {
  if (process.env.NODE_ENV !== 'development') {    
    return 'https://api.ged.supportempresarial.com.br'
  } else {
    return 'https://api.ged.supportempresarial.com.br'
  }
}


const http = axios.create({ baseURL: baseUrl(), timeout: 200000});

export default http;