import { Button, Dialog as DialogMaterial, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

const DialogComponent = (props) => {
  const { open = false, renderAsHtml = false, contenttext, children, title, buttons } = props;

  return (
    <DialogMaterial
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {renderAsHtml && (<div dangerouslySetInnerHTML={{__html: contenttext}}></div>)}
        {contenttext && (<DialogContentText>{contenttext}</DialogContentText>)}
        {children}
      </DialogContent>
      <DialogActions>
        {buttons.map((button, index) => (
          <Button
            style={{...button.style}}
            disabled={button.disabled}
            color={button.color}
            onClick={() => button.onClick()}
            key={index}
          >
            {button.icon}&nbsp;{button.title}
          </Button>
        ))}
      </DialogActions>
    </DialogMaterial>
  );
};

export default DialogComponent;