import http from "./../../services/api";
import queryString from "query-string";
import store from "../index";
import * as types from "./../types/home";
import axios from 'axios';

export const loadHome = filtro => {
  return dispatch => {
    dispatch({ type: types.LOADING_HOME, payload: true });
    http.get(`/api/v1/dashboard/home`).then(
      response => {
        const data = response.data
        console.log(data)
        dispatch([
          { type: types.SET_HOME, payload: data},
          { type: types.LOADING_HOME, payload: false }
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_HOME, payload: false });
      }
    );
  };
};
