import React, { useRef, useState } from 'react';

import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { ImageSearch as ImageSearchIcon } from '@material-ui/icons';
import { CameraOff as CameraOffIcon, Image as ImageIcon } from 'react-feather';

import Modal from '../modal';
import NoImageFound from '../NoImageFound';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  imagesWrapper: {
    display: 'flex',
    gap: '20px'
  },
  imageContainer: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    height: '300px',
    width: '300px'
  },
  buttonContainer: {
    marginTop: '8px',

    display: 'flex',
    justifyContent: 'flex-end'
  },

  changeImageContainer: {
    position: 'absolute',
    bottom: 0,

    padding: '8px',
    width: '100%',
    background: 'rgba(209, 209, 209, 0.5)',

    display: 'flex',
    alignItems: 'center'
  },

  imageName: {
    width: '90%',
    marginLeft: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  changeButton: {
    borderRadius: '50%'
  }
}));

function UploadImage({
  visible,
  close,
  upload,
  uploadParams = {},
  imageUrl,
  alreadyHaveImage,
  showExistenceImage = false
}) {
  const [preview, setPreview] = useState(null);

  const imageRef = useRef(null);
  const classes = useStyles();

  const handleImageChange = image => {
    if (image) {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(image);
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          setPreview(fileReader.result);
        }
      };
    }
  };

  const handleCancelar = () => {
    setPreview(null);
    close();
  };

  const uploadImage = async () => {
    const image = imageRef.current.files[0];
    if (image) {
      const formImage = new FormData();
      formImage.append('file', image);

      if (Object.keys(uploadParams).length > 0) {
        let params = '';

        for (let i in uploadParams) {
          params = params.concat(uploadParams[i] + '/');
        }

        await upload(formImage, params.slice(0, -1));
        handleCancelar();
      } else {
        await upload(formImage);
        handleCancelar();
      }
    }
  };

  return (
    <Modal open={visible}>
      <Box className={classes.container}>
        <Box className={classes.imagesWrapper}>
          {showExistenceImage ? (
            <Box className={classes.imageContainer}>
              <Typography> Imagem atual </Typography>
              {alreadyHaveImage ? (
                <img className={classes.image} src={imageUrl} alt="" />
              ) : (
                <NoImageFound label="Nenhuma imagem cadastrada" />
              )}
            </Box>
          ) : null}
          <Box className={classes.imageContainer}>
            <Typography> Imagem selecionada </Typography>
            {preview ? (
              <img className={classes.image} src={preview} alt="" />
            ) : (
              <NoImageFound />
            )}
            <Box className={classes.changeImageContainer}>
              <ImageIcon />
              <Typography className={classes.imageName}>
                {imageRef?.current?.files.length > 0
                  ? imageRef.current?.files[0].name
                  : 'Selecione uma imagem'}
              </Typography>
              <Button component="label">
                <ImageSearchIcon />
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  ref={imageRef}
                  onChange={() => {
                    handleImageChange(imageRef?.current.files[0]);
                  }}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <Button onClick={handleCancelar}>Cancelar</Button>
        <Button onClick={uploadImage}>Alterar imagem</Button>
      </Box>
    </Modal>
  );
}

export default UploadImage;