const white = '#FFFFFF';
const black = '#000000';

export default {
  common: {
    black: '#000000',
    white: '#ffffff',
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
    principal: '#328AA4'
  },
  primary: {
    contrastText: white,
    dark: '#848688',
    main: '#000041',
    light: '#90be6d'
  },
  secondary: {
    contrastText: white,
    main: '#DF0101',
    light: '#F5A9A9',
    dark: '#610B0B'
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E'
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E'
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08'
  },
  text: {
    primary: '#12161B',
    secondary: '#66788A',
    disabled: '#66788A',
    white: '#F1FBFC'
  },
  background: {
    dark: '#F4F6F8',
    default: '#ffffff',
    paper: '#ffffff'
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
};