import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  home: {
    textAlign: "center",
    fontFamily: "Roboto",
    height: '100vh',
    "& h1": {
      fontSize: "18px",
      marginTop: "3em",
      fontWeight: 'normal'
    },
    "& h2": {
      textAlign: "left",
      fontSize: "17px",
      width: "24%",
      marginLeft: '2em',
      color: '#1d4f91 ',
      borderBottom: "1px solid #1d4f91 ",
    },
  },
  homeContainer:{
    width: '80%',
    margin: 'auto',
    borderRadius: '7px',
    boxShadow: '0px 0px 17px 4px #e9e9e9'
  },
  class1:{
    width: '50%'
   
   
  },
  cardContainer:{
    marginTop: '0.3em',
    boxShadow: "0px 0px 7px 2px #eaeaea",
    lineHeight: '4em',
    textAlign: "center",
    backgroundColor:'#fff',
    padding: '2em',
    borderRadius: '4px',
  
    '& h3':{
      lineHeight: '0em'
    }
  },
  icone:{
    width: '60px',
    height: '60px',
    borderRadius: '30px',
    backgroundColor: 'red'
  }
}));

export default useStyles;