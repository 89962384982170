import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
import {
  EditOutlined,
  MailOutlineOutlined,
  PeopleOutline,
  Visibility,
  VisibilityOff,
  VpnKeyOutlined
} from "@material-ui/icons";
import { Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { RiUserAddLine } from "react-icons/ri";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { InputText, Modal } from "../../components";
import { DataGridTable } from "../../components/index";
import { Cadastro, editarUsuario, listar } from "../../store/actions/usuario";
import * as types from "./../../store/types/usuario";
import { permissoes } from "./permissoes";
import useStyles from "./styles";

function Usuarios() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page, perpage, filtro, total, usuarios, dados_pessoais } =
    useSelector((state) => state.usuario);

  const [dados, setDados] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(dados_pessoais);
  const [showPass, setShowPass] = useState(false);
  const [open_edit_modal, setOpenEdit] = useState(false);
  const [modal_edit, setModalEdit] = useState({
    nome: "",
    email: "",
    permissoes: [],
  });

  const [permissaoSelecionada, setPermissaoSelecionada] = useState([]);

  function ShowPassword() {
    setShowPass(showPass ? false : true);
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const [openDocsUpload, setOpenDocsUpload] = useState(false);

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  const OpenDocs = () => {
    setOpenDocsUpload(openDocsUpload ? false : true);
  };
  const CloseDocs = () => {
    setOpenDocsUpload(openDocsUpload === false);
  };

  const handleChangePage = (newPage) => {
    dispatch([
      { type: types.LOAD_USUARIOS_PAGE, payload: parseInt(newPage) + 1 },
      listar({ ...filtro, page: newPage + 1, per_page: perpage }),
    ]);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch([
      { type: types.LOAD_USUARIOS_PAGE, payload: 1 },
      {
        type: types.LOAD_USUARIOS_PERPAGE,
        payload: parseInt(event.target.value),
      },
      listar({ ...filtro, page: 1, per_page: event.target.value }),
    ]);
  };

  // async function onSubmitForm(event, values)

  const usuarioSchema = yup.object({
    nome: yup.string().required("O nome é obrigatório."),
    email: yup.string().email().required("Digite um email"),
    password: yup
      .string()
      .min(6, "Sua senha deve conter no mínimo 6 caracteres")
      .required("Digite sua senha para cadastro na Dashboard"),
  });

  const fileInputRef = useRef();

  useEffect(() => {
    dispatch(listar({ ...filtro, page: 1, per_page: perpage }));
  }, []);

  const [permissoesUsuarioSelecionado, setPermissoesUsuarioSelecionado] =
    useState([]);

  function OpenEditModal(data) {
    setOpenEdit(true);
    setModalEdit(data);
    const dadas = data.permissoes.split(",").map((s) => Number(s));
    setPermissoesUsuarioSelecionado(dadas);
  }

  const onSubmitForm = async (values) => {
    const _permissoes = values.permissoes.join(",");
    const cadastro = values;
    try {
      await Cadastro({ ...cadastro, permissoes: _permissoes });
      Toast.fire({
        icon: "success",
        title: "Cadastro efetuado com sucesso!",
      });
      dispatch(listar({ ...filtro, page: 1, per_page: perpage }));
      Close();
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Dados incompletos, revise os dados!",
      });
      Close();
    }
  };

  const OnSubmitEditUser = async (values) => {
    const _permissoes = values.permissoes.join(",");
    const usuario = values;
    try {
      await editarUsuario({ ...usuario, permissoes: _permissoes }, values.id);
      dispatch(listar({ ...filtro, page: 1, per_page: perpage }));
      setOpenEdit(false);
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Dados incompletos, revise os dados!",
      });
    }
  };

  const permissionsUser = "";

  const permissoesDisponiveis = permissoes.filter(
    (item) => !permissoesUsuarioSelecionado?.includes(item.id)
  );

  const permissaoValidacao = permissoesDisponiveis.map((item) => item.id);

  console.log(permissaoValidacao);

  //
  return (
    <>
      <div className={classes.user}>
        <div className={classes.addUser}>
          <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
            Cadastro de Usuários:
          </h4>
          <Divider />
          <br />
          <Grid container spacing={1} alignItems="center">
            <Grid item sm={8} xs={8}>
              {/* <InputDebounce
                label="Digite o nome ou CPF para pesquisar"
                value={dados.pesquisa}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => console.log("filtro")}
              /> */}
            </Grid>
            <Grid item sm={4} xs={4}>
              <div style={{ float: "right", marginRight: "1em" }}>
                <button
                  onClick={Open}
                  style={{
                    border: "none",
                    padding: "12px 15px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#000041",
                    color: "#fff",
                    cursor: "pointer",
                    borderRadius: "5px",
                    marginTop: "0.6em",
                    fontSize: "10px",
                  }}
                >
                  <RiUserAddLine style={{ fontSize: "16px" }} />
                  &nbsp; ADICIONAR USUÁRIO
                </button>
              </div>
            </Grid>
          </Grid>

          <Grid sm={12} style={{ marginTop: "2em" }}>
            <PerfectScrollBar>
              <DataGridTable
                columns={[
                  // { title: "Data de Admissão", field: "date", cellStyle: { textAlign: "center" }, },
                  { title: "Funcionário", field: "nome" },
                  {
                    title: "Email",
                    field: "email",
                    cellStyle: { textAlign: "center" },
                  },
                  {
                    title: "Ações",
                    cellStyle: { width: "15%", textAlign: "center" },
                    render: (row) => {
                      return (
                        <>
                          <Tooltip title="Editar Usuario">
                            <button
                              style={{
                                cursor: "pointer",
                                background: "transparent",
                                border: "none",
                              }}
                              onClick={() => OpenEditModal(row)}
                            >
                              <EditOutlined style={{ width: "16px" }} />{" "}
                            </button>
                          </Tooltip>
                        </>
                      );
                    },
                  },
                ]}
                data={usuarios}
                options={{
                  headerStyle: {
                    backgroundColor: "#F2F2F3",
                    fontSize: 12,
                    textAlign: "center",
                    borderLeft: "solid 0.5px white",
                  },
                  rowStyle: (rowData) => ({
                    fontSize: 14,
                    textAlign: "center",
                  }),
                }}
                // onRowClick={(event, rowData) => navigate(`${rowData.id_transacao}`)}
              />
            </PerfectScrollBar>
          </Grid>
        </div>
      </div>
      <Modal open={open} width="50%">
        Cadastrar Usuário
        <Divider />
        <Formik
          initialValues={data}
          onSubmit={onSubmitForm}
          validationSchema={usuarioSchema}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => {
            return (
              <>
                <Grid container spacing={1} alignItems="center">
                  <Grid item sm={12} xs={12}>
                    <div className={classes.inputStyle}>
                      <PeopleOutline
                        size="25"
                        style={{ marginRight: "0.5em", color: "#b5b5b5" }}
                      />
                      <InputText
                        variant="standard"
                        name="nome"
                        label="Nome"
                        value={values.nome}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={
                          Boolean(touched.nome && errors.nome) && errors.nome
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <div className={classes.inputStyle}>
                      <MailOutlineOutlined
                        size="25"
                        style={{ marginRight: "0.5em", color: "#b5b5b5" }}
                      />
                      <InputText
                        value={values.email}
                        type="email"
                        onChange={handleChange}
                        label="Email"
                        name="email"
                        variant="standard"
                        placeholder="email@email.com"
                        onBlur={handleBlur}
                        error={Boolean(touched.email && errors.email)}
                        helperText={
                          Boolean(touched.email && errors.email) && errors.email
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <div className={classes.inputStyle}>
                      <VpnKeyOutlined
                        size="25"
                        style={{ marginRight: "0.5em", color: "#b5b5b5" }}
                      />
                      <InputText
                        value={values.password}
                        type={showPass ? "text" : "password"}
                        onChange={handleChange}
                        label="Senha"
                        name="password"
                        variant="standard"
                        onBlur={handleBlur}
                        error={Boolean(touched.password && errors.password)}
                        helperText={
                          Boolean(touched.password && errors.password) &&
                          errors.password
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ cursor: "Pointer", opacity: "0.7" }}
                              onClick={ShowPassword}
                            >
                              <IconButton onClick={ShowPassword}>
                                {showPass ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} style={{ lineHeight: "25px" }}>
                    <h4>Permissões</h4>
                    <Grid container spacing={1} alignItems="center">
                      <div>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="2" />{" "}
                          - Cadastro de Arquivos
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="7" />{" "}
                          - Cadastro de Funcionários
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="9" />{" "}
                          - Cadastro de Empresas
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="10" />{" "}
                          - Tipos de Arquivos
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="11" />{" "}
                          - Vincular Funcionário e Empresa
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="8" />{" "}
                          - Cadastro de Usuários Plataforma
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={6} style={{ lineHeight: "25px" }}>
                    <Grid container spacing={1} alignItems="center">
                      <div>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="12" />{" "}
                          - Permissão Upload de Arquivo
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="13" />{" "}
                          - Permissão Excluir Arquivo
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="14" />{" "}
                          - Permissão Criar Pasta Arquivos/Funcionários
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="15" />{" "}
                          - Ver Informações de Arquivos
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Field type="checkbox" name="permissoes" value="16" />{" "}
                          - Permissão Baixar Arquivos
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    className={classes.btnContainer}
                    style={{ textAlign: "center" }}
                  >
                    <button
                      className={classes.buttonDialog}
                      type="submit"
                      onClick={handleSubmit}
                      style={{ color: "#fff", backgroundColor: "#000041" }}
                    >
                      Cadastrar
                    </button>
                    <button
                      onClick={Close}
                      className={classes.buttonDialog}
                      style={{
                        color: "gray",
                        backgroundColor: "#fff",
                        border: "1px solid gray",
                      }}
                    >
                      Fechar
                    </button>
                  </div>
                </Grid>
              </>
            );
          }}
        </Formik>
      </Modal>

      <Modal open={open_edit_modal} width="50%">
        Editar Usuário
        <Divider />
        <Formik initialValues={modal_edit} onSubmit={OnSubmitEditUser}>
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            errors,
            touched,
          }) => {
            return (
              <>
                <Grid container spacing={1} alignItems="center">
                  <Grid item sm={12} xs={12}>
                    <div className={classes.inputStyle}>
                      <PeopleOutline
                        size="25"
                        style={{ marginRight: "0.5em", color: "#b5b5b5" }}
                      />
                      <InputText
                        variant="standard"
                        name="nome"
                        label="Nome"
                        value={values.nome}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid>

                  {/* <Grid item sm={12} xs={12}>
                    <div className={classes.inputStyle}>
                      <MailOutlineOutlined
                        size="25"
                        style={{ marginRight: "0.5em", color: "#b5b5b5" }}
                      />
                      <InputText
                        value={values.email}
                        type="email"
                        onChange={handleChange}
                        label="Email"
                        name="email"
                        variant="standard"
                        placeholder="email@email.com"
                        onBlur={handleBlur}
                      />
                    </div>
                  </Grid> */}
                 

                  <Grid item sm={6} xs={6} style={{ lineHeight: "25px" }}>
                    <h4>Permissões</h4>
                    <Grid container spacing={1} alignItems="center">
                      <div>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(2) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="2" />{" "}
                          - Cadastro de Arquivos
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(7) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="7" />{" "}
                          - Cadastro de Funcionários
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(9) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="9" />{" "}
                          - Cadastro de Empresas
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(10) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="10" />{" "}
                          - Tipos de Arquivos
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(11) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="11" />{" "}
                          - Vincular Funcionário e Empresa
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(8) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="8" />{" "}
                          - Cadastro de Usuários Plataforma
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={6} style={{ lineHeight: "25px" }}>
                    <Grid container spacing={1} alignItems="center">
                      <div>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(12) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="12" />{" "}
                          - Permissão Upload de Arquivo
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(13) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="13" />{" "}
                          - Permissão Excluir Arquivo
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(14) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="14" />{" "}
                          - Criar Pasta Arquivos/Funcionários
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(15) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="15" />{" "}
                          - Ver Informações de Arquivos
                        </Grid>
                        <Grid item sm={12} xs={12} style={{color: permissoesUsuarioSelecionado.includes(16) ? '#11931a' : 'gray'}}>
                          <Field type="checkbox" name="permissoes" value="16" />{" "}
                          - Permissão Baixar Arquivos
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    className={classes.btnContainer}
                    style={{ textAlign: "center" }}
                  >
                    <button
                      className={classes.buttonDialog}
                      type="submit"
                      onClick={handleSubmit}
                      style={{ color: "#fff", backgroundColor: "#000041" }}
                    >
                      Cadastrar
                    </button>
                    <button
                      onClick={() => setOpenEdit(false)}
                      className={classes.buttonDialog}
                      style={{
                        color: "gray",
                        backgroundColor: "#fff",
                        border: "1px solid gray",
                      }}
                    >
                      Fechar
                    </button>
                  </div>
                </Grid>
              </>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

export default Usuarios;
