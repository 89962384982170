import { Divider } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { useState } from "react";
import { Send } from "react-feather";
import { useDispatch } from "react-redux";
import { DialogComponent, Modal } from "../../../components";
import {
  inserirPastas,
  loadPastaFuncionario
} from "../../../store/actions/pastas";
import { loadFuncionarioVinculo } from "../../../store/actions/vinculo_pessoa_empresa";

function ModalCriarPasta({
  open,
  categorias,
  setClose,
  collection,
  idFuncionario,
}) {
  const dispatch = useDispatch();
  const [openCriacaodePasta, setOpenCriacaodePasta] = useState(false);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState();

  async function CriarPasta(categoria) {
    setCategoriaSelecionada(categoria);
    setOpenCriacaodePasta(true);
  }

  async function ConfirmacaoCriacaoPasta() {
    try {
      await inserirPastas({
        categoria_id: categoriaSelecionada.id,
        collection_id: collection,
      });
      setClose();
      setOpenCriacaodePasta(false);
      dispatch([
        loadPastaFuncionario(idFuncionario),
        loadFuncionarioVinculo(idFuncionario),
      ]);
    } catch (e) {
      console.log("Não foi possível criar a pasta");
    }
  }

  return (
    <Modal open={open}>
      {categorias
        ? "Não foi possível listar as pastas"
        : "Selecione a Pasta que deseja criar"}
      <br />
      <Divider />
      <div style={{ margin: "15px 0px" }}>
        {categorias ? (
          "Funcionário já possui todas as pastas cadastradas!"
        ) : (
          <>
            {categorias.map((item, index) => {
              return (
                <button
                  style={{ cursor: "pointer", width: "100%", marginTop: "5px" }}
                  key={index.id}
                  onClick={() => CriarPasta(item)}
                >
                  {item.descricao}
                </button>
              );
            })}
          </>
        )}
      </div>
      <Divider />
      <button 
         style={{
          padding: '7px 25px',
          backgroundColor: "transparent",
          borderRadius: '5px',
          cursor: "pointer",
          border: '1px solid #b51c1c',
          color: '#590909',
          float: 'right',
          marginTop: '10px'

        }} onClick={setClose}>
       
        Fechar
      </button>

      <DialogComponent
        open={openCriacaodePasta}
        title="Confirmar a criação da Pasta?"
        style={{ zIndex: 999 }}
        contenttext={<>Pasta selecionada {categoriaSelecionada?.descricao}</>}
        buttons={[
          {
            title: "Cancelar",
            icon: <Clear />,
            style: { color: "gray" },
            onClick: () => {
              setOpenCriacaodePasta(false);
            },
          },
          {
            title: "Criar Pasta",
            icon: <Send />,
            style: { color: "green" },
            onClick: ConfirmacaoCriacaoPasta,
          },
        ]}
      />
    </Modal>
  );
}

export default ModalCriarPasta;
