import http from "./../../services/api";
import store from "../index";
import * as types from "./../types/categoria_arquivos";

export const loadCategorias = filtro => {
  return dispatch => {
    dispatch({ type: types.LOADING_CATEGORIAS, payload: true });
    http.get(`/api/v1/categoria/list`).then(
      response => {
        const data = response.data
        dispatch([
          { type: types.SET_CATEGORIA, payload: data},
          { type: types.LOADING_CATEGORIAS, payload: false }
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_CATEGORIAS, payload: false });
      }
    );
  };
};



export function inserirCategoria(categoria ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_CATEGORIAS, payload: true });
    http.post(`/api/v1/categoria/insert`, categoria)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_CATEGORIAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_CATEGORIAS, payload: false });
          reject();
        }
      );
  });
}

export function editarCategoria(categoria, id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_CATEGORIAS, payload: true });
    http.put(`/api/v1/categoria/edit/${id}`, categoria)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_CATEGORIAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_CATEGORIAS, payload: false });
          reject();
        }
      );
  });
}

export function disabledFuncionario(id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_CATEGORIAS, payload: true });
    http.put(`/api/v1/funcionarios/disable/${id}`,)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_CATEGORIAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_CATEGORIAS, payload: false });
          reject();
        }
      );
  });
}

