import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  user:{
    width: '100%',
    height: '100vh'
  },
  AddFuncionario:{
    width: '50%',
    margin: 'auto',
    textAlign: 'center',
    marginTop: '5em',
    boxShadow: '0px 0px 15px 0px #e3e3e3',
    borderRadius: '7px',
    marginBottom: '5em',
    '& h1':{
      fontSize: '16px',
    },
    '& select':{
      width: '100%',
      border: 'none',
      borderBottom: '1px solid #919191',
      padding: '5px 5px',
      marginBottom: '4em',
    }
  },
  AddFuncionarioContainer:{
    width: '90%',
    margin: 'auto'
  },
  text:{
    width: '95%'
  },
  select:{
    width: '95%',
    textAlign: 'left',
    margin: 'auto',

  },
  button:{   
    width: "185px",
    height: "30px",
    border: "none",
    color: "#fff",
    background: "#3D56A2",
    borderRadius: "5px",
    transition: "ease-out 0.5s",
    fontSize: "12px",
    outline: "none",
    marginTop: '3em',
    "&:hover": {
      boxShadow: "inset 185px 0 0 0 #485fa6",
      cursor: "pointer",
  }
}
}));

export default useStyles;