import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  user:{
    width: '100%',
    height: '100vh'
  },
  addUser:{
    width: '95%',
    padding: '1em',
    margin: 'auto',
    textAlign: 'center',
    marginTop: '1em',
    boxShadow: '0px 0px 15px 0px #e3e3e3',
    '& h1':{
      fontSize: '16px',
    },
  },
  centerbtn:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  btnUpload:{
    
    '& button':{
      
      border: 'none',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: "#24A0D5",
      color: '#fff',
      cursor: 'pointer',
      borderRadius: '5px'
    }
  },
  btnDownload:{
    textAlign: 'center',
    '& button':{
      border: 'none',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: "#e8ab1e",
      color: '#fff',
      cursor: 'pointer',
      borderRadius: '5px'
    }
  },
  btnAcesso:{
    textAlign: 'center',
    '& button':{
      border: 'none',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: "#16A891",
      color: '#fff',
      cursor: 'pointer',
      borderRadius: '5px'
    }
  },
  inputStyle: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    display: "flex",
  },
  buttonNext: {
    border: "none",
    padding: "10px 30px",
    borderRadius: "7px",
    marginTop: "3em",
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    cursor: "pointer",
    background: "linear-gradient(90deg, #36c987 17%, #1ce78e 93%)",
  },
  btnContainer:{
    width: '90%',
    margin: 'auto',
    marginBottom: '1em',
    marginTop: '1em',
    '& h2':{
      fontSize: '24px',
      fontWeight: 'normal',
      lineHeight: '30px',
     marginTop: '2em'
    }
  },
  buttonDialog:{
    padding: '13px 27px',
    border: 'none',
    textAlign: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '1em'
  },
}));

export default useStyles;