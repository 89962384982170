import store from "../index";
import http from "./../../services/api";
import * as types from "./../types/arquivos";

export function loadArquivos(id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_ARQUIVOS, payload: true });
    http.get(`/api/v1/arquivos/list/dir/${id}`).then(
      (response) => {
        const data = response.data;
        dispatch([
          { type: types.SET_ARQUIVOS, payload: data },
          { type: types.LOADING_ARQUIVOS, payload: false },
        ]);
      },
      (error) => {
        console.log(error);
        dispatch({ type: types.LOADING_ARQUIVOS, payload: false });
      }
    );
  };
}





export function DownloadArquivo(key) {
  return new Promise((resolve, reject) => {
    http
      .get(`/api/v1/arquivos/download?k=${key}`, { responseType: "blob" })
      .then((response) => {
        const pdfBlob = new Blob([response.data], {
          type: "application/pdf",
        });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(pdfBlob);
        }

        const data = window.URL.createObjectURL(pdfBlob);
        window.open(data, "_blank");
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function ExcluirArquivoAWS(id_arquivo, key ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_ARQUIVOS, payload: true });
    http.delete(`/api/v1/arquivos/remove/${id_arquivo}?k=${key}`,)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_ARQUIVOS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_ARQUIVOS, payload: false });
          reject();
        }
      );
  });
}

