import palette from '../pallete';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    minHeight: 50,  //35,
    lineHeight: 1,
    // textAlign: 'center',
    position: 'static',
    padding: 0,
    // fontSize: '18!important'
  },
  // head: {
  //   fontSize: 12,
  //   color: palette.text.primary
  // },
  // sizeSmall: {
  //   padding: 0,
  //   fontSize: 10,
  // }
};