import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  user:{
    width: '100%',
    height: '100vh'
  },
  addUser:{
    width: '95%',
    margin: 'auto',
    textAlign: 'center',
    marginTop: '3em',
    boxShadow: '0px 0px 15px 0px #e3e3e3',
    '& h1':{
      fontSize: '16px',
    },
  }
}));

export default useStyles;