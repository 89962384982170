import queryString from 'query-string';
import store from "../index";
import http from "./../../services/api";
import * as types from "./../types/pastas";

export const loadPastas = doc => {

  const qry = queryString.stringify(doc,{ arrayFormat: 'index',  });

  return dispatch => {
    dispatch({ type: types.LOADING_PASTAS, payload: true });
    http.get(`/api/v1/pastas/list/funcionarios?${qry}`,).then(
      response => {  
        const { total, rows, page, data} = response.data;       

     
        dispatch([
          { type: types.SET_PASTAS, payload: data},
          { type: types.LOADING_PASTAS, payload: false },
          { type: types.PASTAS_PAGE, payload: doc.page },
          { type: types.PASTAS_PERPAGE, payload: doc.rows},
          { type: types.PASTAS_TOTAL, payload: parseInt(total)},
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_PASTAS, payload: false });
      }
    );
  };
};

export const AvailableCategorys = id => {

  return dispatch => {
    dispatch({ type: types.LOADING_PASTAS, payload: true });
    http.get(`/api/v1/pastas/available-categorys/${id}`,).then(
      response => {  
        const data = response.data;          
        dispatch([
          { type: types.SET_CATEGORIA_DISPONIVEL, payload: data},
          { type: types.LOADING_PASTAS, payload: false },
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_PASTAS, payload: false });
      }
    );
  };
};



export const loadPastaFuncionario = id => {

  return dispatch => {
    dispatch({ type: types.LOADING_PASTAS, payload: true });
    http.get(`/api/v1/pastas/list/${id}`,).then(
      response => {  
        const [data] = response.data;    
        dispatch([
          { type: types.SET_PASTA_FUNCIONARIO, payload: data},
          { type: types.LOADING_PASTAS, payload: false },
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_PASTAS, payload: false });
      }
    );
  };
};


export const ConsultaPastaFuncionario = id => {

  return dispatch => {
    dispatch({ type: types.LOADING_PASTAS, payload: true });
    http.get(`/api/v1/pastas/available-categorys/${id}`,).then(
      response => {  
        const data = response.data; 
        dispatch([
          { type: types.SET_CATEGORIA_DISPONIVEL, payload: data},
          { type: types.LOADING_PASTAS, payload: false },
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_PASTAS, payload: false });
      }
    );
  };
};





export function inserirPastas(pastas ) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_PASTAS, payload: true });
    http.post(`/api/v1/pastas/insert`, pastas)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_PASTAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_PASTAS, payload: false });
          reject();
        }
      );
  });
}

export function editarPastas(categoria, id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_PASTAS, payload: true });
    http.post(`/api/v1/pastas/edit/${id}`, categoria)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_PASTAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_PASTAS, payload: false });
          reject();
        }
      );
  });
}

export function disabledFuncionario(id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_PASTAS, payload: true });
    http.put(`/api/v1/funcionarios/disable/${id}`,)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_PASTAS, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_PASTAS, payload: false });
          reject();
        }
      );
  });
}

export const upload_arquivos = async (dados) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_PASTAS, payload: true });
    http.post(`/api/v1/arquivos/insert`, dados, {
      'Content-Type': 'multipart/form-data'
    }).then(
      ({ data }) => {
        resolve();
        store.dispatch({ type: types.LOADING_PASTAS, payload: false });
      },
      (e) => {
        store.dispatch({ type: types.LOADING_PASTAS, payload: false });
        reject();
      }
    );
  });
};