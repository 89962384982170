import React from "react";



function Dashboard() {


  return (
    <>
      <div >
        <h1>
          Olá Dashboard
        </h1>
        
      </div>
    </>
  );
}

export default Dashboard;