import React, { useState } from "react";
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  AccordionActions,
  Button,
  Divider,
} from "@material-ui/core";
import { ChevronDown, ArrowDown, XCircle, Filter } from "react-feather";

function FilterBar(props) {
  const [expanded, setExpanded] = useState(false);

  return (
      <Accordion
        styles={props.styles}
        expanded={expanded}
        onChange={ () => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ChevronDown />}
        >
          <Typography styles={{ width: "33%", flexShrink: 0, fontSize: "1px" }}>
            Clique aqui para vizualizar as opções de filtro:
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails styles={{fontSize: "12px"}}>
          {props.children}
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            size="small"
            onClick={() => { setExpanded(!expanded); props.filter(); }}>
            <Filter size={20} style={{marginRight: "5px"}} /> Filtrar
          </Button>
          <Button
            size="small"
            styles={{color: "#5e5e5e"}}
            onClick={() => { setExpanded(!expanded); props.clear(); }}>
            <XCircle size={20} style={{marginRight: "5px"}}  /> Limpar
          </Button>
        </AccordionActions>
      </Accordion>
  );
}

export default FilterBar;