import { Divider, Grid, Tooltip } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { BiCategoryAlt } from "react-icons/bi";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGridTable, InputSelect, InputText } from "../../components/index";
import Modal from "../../components/modal";
import { loadCategorias } from "../../store/actions/categoria_arquivos";
import {
    editarTipodeArquivo,
    inserirTipodeArquivo,
    loadTipodeArquivo
} from "../../store/actions/tipos_de_arquivos";
import useStyles from "./styles.jsx";

function TiposDeArquivos() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tipo_de_arquivos } = useSelector((state) => state.tipo_de_arquivos);
  const { categorias } = useSelector((state) => state.categoria_arquivo);

  const [dados, setDados] = useState([]);
  const [open, setOpen] = useState(false);

  const [paginacao, setPaginacao] = useState({
    perpage: 10,
    total: 0,
    page: 0,
  });
  const [openDocsUpload, setOpenDocsUpload] = useState(false);
  const [open_edit, setOpenEdit] = useState(false);
  const [modal_edit, setModalEdit] = useState({
    descricao: "",
    categoria_id: "",
  });
  const [modal_insert, setModalInsert] = useState({ descricao: "" });
  const [categoriaSelect, setCategoriaSelect] = useState();

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  const OpenDocs = () => {
    setOpenDocsUpload(openDocsUpload ? false : true);
  };
  const CloseDocs = () => {
    setOpenDocsUpload(openDocsUpload === false);
  };

  useEffect(() => {
    dispatch([loadTipodeArquivo(), loadCategorias()]);
  }, []);

  const handleChange = () => {
    // do something with event data
  };

  function OpenEditModal(data) {
    setOpenEdit(true);
    setModalEdit(data);
    console.log("Informacoes da data", data);
  }

  function CloseInsertModal() {
    setModalInsert({ nome: "", cpf_cnpj: "" });
    Close();
  }

  async function OnSubmitCategoria() {
    try {
      await inserirTipodeArquivo({
        descricao: modal_insert.descricao,
        categoria_id: categoriaSelect,
      });
      dispatch(loadTipodeArquivo());
      CloseInsertModal();
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }

  async function OnSubmitEditCategoria(id) {
    try {
      await editarTipodeArquivo(
        {
          descricao: modal_edit.descricao,
          categoria_id: categoriaSelect,
        },
        id
      );
      dispatch(loadTipodeArquivo());
      setOpenEdit(false);
    } catch (error) {
      console.log("erro ao cadastrar");
    }
  }


  const ListRender = (item) => categorias && categorias.find((lista) => lista.id == item?.categoria_id).descricao


  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
        <h4 style={{ margin: 0, textAlign: "left", marginBottom: "0.5em" }}>
          Tipos de Arquivo:
        </h4>
        <Divider />
        <br />
        <Grid container spacing={2}>
          <Grid item sm={8} xs={8}>
            {/* <InputDebounce
              label="Digite o nome ou CPF para pesquisar"
              value={dados.pesquisa}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => console.log("filtro")}
            /> */}
          </Grid>
          <Grid item sm={4} xs={4}>
            <div style={{ float: "right", marginRight: "1em" }}>
              <button
                onClick={Open}
                style={{
                  border: "none",
                  padding: "12px 15px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#000041",
                  color: "#fff",
                  cursor: "pointer",
                  borderRadius: "5px",
                  marginTop: "0.9em",
                  fontSize: "10px",
                }}
              >
                <BiCategoryAlt style={{ fontSize: "16px" }} />
                &nbsp; INSERIR TIPO
              </button>
            </div>
          </Grid>
        </Grid>

        <Grid sm={12} style={{ marginTop: "2em" }}>
          <PerfectScrollBar>
            <DataGridTable
              columns={[
                {
                  title: "Data de criação",
                  cellStyle: { width: "20%", textAlign: "center" },
                  render: (row) => dayjs(row.created_at).format("DD/MM/YYYY"),
                },
                { title: "Descrição", field: "descricao" },
                {
                  title: "Categoria Arquivo",
                  field: "categoria_id",
                  render: (row) =>
                    row.categoria_id && ListRender(row)
                },
                {
                  title: "Status",
                  field: "status",
                  render: (row) => (row.status == "A" ? "Ativo" : "Inativo"),
                  cellStyle: { width: "15%", textAlign: "center" },
                },
                {
                  title: "Ações",
                  cellStyle: { width: "15%", textAlign: "center" },
                  render: (row) => {
                    return (
                      <>
                        {/* <Tooltip title="Desativar Funcionário">
                   <button style={{cursor: 'pointer', background: 'transparent', border: 'none'}} onClick={() => DisabledFuncionario(row.id)}><PersonAddDisabled style={{width: '16px'}}/> </button>
                   </Tooltip> &nbsp; */}
                        <Tooltip title="Editar Tipo de arquivo">
                          <button
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              border: "none",
                            }}
                            onClick={() => OpenEditModal(row)}
                          >
                            <EditOutlined style={{ width: "16px" }} />{" "}
                          </button>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
              data={tipo_de_arquivos}
              options={{
                headerStyle: {
                  backgroundColor: "#F2F2F3",
                  fontSize: 12,
                  textAlign: "center",
                  borderLeft: "solid 0.5px white",
                },
              }}
              // onRowClick={(event, rowData) => navigate(`${rowData.id_transacao}`)}
            />
            {/* <TablePaginate
              rowsPerPageOptions={[10, 30, 50, 100]}
              rowsPerPage={parseInt(perpage)}
              count={parseInt(total)}
              page={parseInt(page) - 1}
              onChangePage={(e, newPage) => handleChangePage(newPage)}
              onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
            /> */}
          </PerfectScrollBar>
        </Grid>

        <Modal open={open} style={{ padding: "20px 30px", width: "40%" }}>
          <h5 style={{ margin: 0 }}>
            Insira as informações para adicionar os tipos de arquivos
          </h5>
          <Divider style={{ margin: "1em 0em 1em 0em" }} />

          <InputText
            label="Descrição"
            value={modal_insert.descricao}
            onChange={(e) =>
              setModalInsert({ ...modal_insert, descricao: e.target.value })
            }
          />

          <InputSelect
            label="Categoria do arquivo"
            value={categoriaSelect}
            onChange={(e) => setCategoriaSelect(e.target.value)}
            values={[{ value: "", label: "Selecione" }].concat(
              categorias &&
                categorias.map((item) => ({
                  value: item.id,
                  label: item.descricao,
                }))
            )}
          />

          <Divider style={{ margin: "1em 0em 1em 0em" }} />
          <div style={{ float: "right" }}>
            <button
              onClick={OnSubmitCategoria}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
             
              Cadastrar
            </button>{" "}
            &nbsp;
            <button
              onClick={() => CloseInsertModal()}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
             
              Fechar
            </button>
          </div>
        </Modal>
        <Modal open={open_edit} style={{ padding: "20px 30px", width: "40%" }}>
          <h5 style={{ margin: 0 }}>Editar o tipo do arquivo</h5>
          <Divider style={{ margin: "1em 0em 1em 0em" }} />

          <InputText
            label="Descrição"
            value={modal_edit.descricao}
            onChange={(e) =>
              setModalEdit({ ...modal_edit, descricao: e.target.value })
            }
          />

          <InputSelect
            label="Categoria do arquivo"
            value={categoriaSelect}
            onChange={(e) => setCategoriaSelect(e.target.value)}
            values={[{ value: "", label: "Selecione" }].concat(
              categorias &&
                categorias.map((item) => ({
                  value: item.id,
                  label: item.descricao,
                }))
            )}
          />

          <Divider style={{ margin: "1em 0em 1em 0em" }} />
          <div style={{ float: "right" }}>
            <button
              onClick={() => OnSubmitEditCategoria(modal_edit.id)}
              style={{
                border: "1px solid #3CA011",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "#3CA011",
                cursor: "pointer",
              }}
            >
             
              Editar
            </button>
            &nbsp;
            <button
              onClick={() => setOpenEdit(false)}
              style={{
                border: "1px solid red",
                background: "transparent",
                padding: "7px 15px ",
                borderRadius: "4px",
                color: "red",
                cursor: "pointer",
              }}
            >
             
              Fechar
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default TiposDeArquivos;
