import queryString from "query-string";
import store from "../index";
import http from "./../../services/api";
import * as types from "./../types/usuario";

export const autenticarUsuario = async (email, senha) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_USUARIOS, payload: true });
    http.post(`/api/v1/auth/autenticar`, { email, senha }).then(
      ({ data }) => {
        store.dispatch([
          { type: types.SET_USUARIOS, payload: data.usuario },
          {
            type: types.LOAD_USUARIOS_PERMISSAO,
            payload: data.usuario.permissoes,
          },
        ]);
        localStorage.setItem("token", data.token);
        localStorage.setItem("id", data.usuario.id);
        localStorage.setItem("usuario", data.usuario.nome);
        localStorage.setItem("permissoes", data.usuario.permissoes);
        store.dispatch({ type: types.LOADING_USUARIOS, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.LOADING_USUARIOS, payload: false });
        reject();
      }
    );
  });
};

export const actionResetSenha = async (email) => {
  store.dispatch({ type: types.LOADING_RESET_SENHA, payload: true });

  return new Promise((resolve, reject) => {
    http.post(`/api/v1/usuario/recuperar-senha/gerar-hash`, {email: email}).then(
      ({ data }) => {
    store.dispatch({ type: types.LOADING_RESET_SENHA, payload: false });

        resolve();
      },
      (e) => {
    store.dispatch({ type: types.LOADING_RESET_SENHA, payload: false });

        reject();
      }
    );
  });
};


export const actionCadastrarNovaSenha = async (hash, email) => {
  store.dispatch({ type: types.LOADING_RESET_SENHA, payload: true });

  return new Promise((resolve, reject) => {
    http.post(`/api/v1/usuario/recuperar-senha/trocar/${hash}`,  email).then(
      ({ data }) => {
    store.dispatch({ type: types.LOADING_RESET_SENHA, payload: false });

        resolve();
      },
      (e) => {
    store.dispatch({ type: types.LOADING_RESET_SENHA, payload: false });

        reject();
      }
    );
  });
};

export const listar = (filtro) => {
  const qry = queryString.stringify(filtro, { arrayFormat: "index" });
  return (dispatch) => {
    dispatch({ type: types.LOADING_USUARIOS, payload: true });
    http.get(`/api/v1/usuario/list?${qry}`).then(
      (response) => {
        const { total, current_page: page, per_page, data } = response.data;
        dispatch([
          { type: types.SET_USUARIOS_LIST, payload: response.data.list },
          { type: types.LOAD_USUARIOS_PAGE, payload: page },
          { type: types.LOAD_USUARIOS_PERPAGE, payload: per_page },
          { type: types.LOAD_USUARIOS_TOTAL, payload: total },
          { type: types.LOADING_USUARIOS, payload: false },
        ]);
      },
      (error) => {
        console.log(
          "Não foi possível carregar os usuários cadastrados.",
          "error"
        );
        dispatch({ type: types.LOADING_USUARIOS, payload: false });
      }
    );
  };
};

export const loadUsuario = (id) => {
  return (dispatch) => {
    dispatch([{ type: types.LOADING_USUARIOS, payload: true }]);
    http.get(`/api/v1/usuario/${id}`).then(
      (response) => {
        dispatch([
          { type: types.SET_USUARIOS, payload: response.data },
          { type: types.LOADING_USUARIOS, payload: false },
        ]);
      },
      (error) => {
        dispatch([{ type: types.LOADING_USUARIOS, payload: false }]);
        console.log(error);
      }
    );
  };
};

export function Cadastro(dados_cadastro) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_USUARIOS, payload: true });
    http.post(`/api/v1/usuario/insert`, dados_cadastro).then(
      ({ data }) => {
        store.dispatch({ type: types.DADOS_PESSOAIS_SET, payload: data });
        store.dispatch({ type: types.LOADING_USUARIOS, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.LOADING_USUARIOS, payload: false });
        reject();
      }
    );
  });
}

export function editarUsuario(usuario, id) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_USUARIOS, payload: true });
    http.post(`/api/v1/usuario/edit/${id}`, usuario).then(
      ({ data }) => {
        store.dispatch({ type: types.LOADING_USUARIOS, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.LOADING_USUARIOS, payload: false });
        reject();
      }
    );
  });
}
