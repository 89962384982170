import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    display: 'inline-block',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    maxWidth: '800px'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px'
  },
  modalButton: {
    borderRadius: '0px'
  }
  /* confirmButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  closeButton: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main
  } */
}));

export default useStyles;