import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DataTable } from "../../../components/index";
import { loadArquivos } from "../../../store/actions/arquivos";
import {
    ConsultaPastaFuncionario,
    loadPastaFuncionario
} from "../../../store/actions/pastas";
import { ListarTiposPorCategoria } from "../../../store/actions/tipos_de_arquivos";
import { loadFuncionarioVinculo } from "../../../store/actions/vinculo_pessoa_empresa";
import ModalArquivosDigitalizados from "./modalArquivos";
import ModalCriarPasta from "./modalCriarPasta";
import ModalDigitalizarArquivo from "./modalDigitalizarArquivos";

function OpenFolderEmployee(data) {
  const dispatch = useDispatch();
  const { idFuncionario } = useParams();
  const { pastaFuncionario, categoria_disponivel } = useSelector(
    (state) => state.pastas
  );
  const { arquivos } = useSelector((state) => state.arquivos);
  const { listaPorCategoria } = useSelector((state) => state.tipo_de_arquivos);
  const { funcionarioVinculado } = useSelector(
    (state) => state.vinculo_pessoa_empresa
  );

  const [data_modal_list_arquivos, setDataModalListArquivos] = useState({});
  const [listaDeArquivos, setListadeArquivos] = useState();
  const [openModalArquivos, setOpenModalArquivos] = useState(false);
  const [openModalUploadArquivo, setOpenModalUploadArquivo] = useState(false);
  const [openModalCriarPasta, setOpenModalCriarPasta] = useState(false);

  async function setOpenListArquivos(data) {
    try {
      await dispatch([loadArquivos(data.id)]);
      setOpenModalArquivos(true);
      setListadeArquivos(data.id);
      setDataModalListArquivos(data);
    } catch {
      console.log("erro");
    }
  }

  console.log("O que vem na pasta", pastaFuncionario);

  const [permissions, setPermissions] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    const _permissoes = localStorage.getItem("permissoes");
    setPermissions(_permissoes);
    dispatch([
      loadPastaFuncionario(idFuncionario),
      loadFuncionarioVinculo(idFuncionario),
    ]);
  }, []);

  const [useFolderUpload, setFolderUpload] = useState();

  function SelectFolderToUpload(data) {
    dispatch(ListarTiposPorCategoria({ categoria_id: data.categoria_id }));
    console.log("Select", data);
    setFolderUpload(data);
    setOpenModalUploadArquivo(true);
  }

  function CreatefolderEmployee() {
    dispatch(ConsultaPastaFuncionario(funcionarioVinculado[0]?.id));
    setOpenModalCriarPasta(true);
  }

  return (
    <div>
      {permissions?.includes(14) && 
      <button
        style={{
          float: "right",
          marginRight: 40,
          padding: "5px 25px",
          borderRadius: "15px",
          backgroundColor: "transparent",
          cursor: "pointer",
          border: "1px solid #000041",
        }}
        onClick={() => CreatefolderEmployee()}
      >
        Criar pasta
      </button>}
      <div style={{ margin: "25px 12px" }}>
        Abaixo as pastas criadas para o funcionário:{" "}
        <b>{pastaFuncionario[0]?.nome}</b>
      </div>
      <Divider />
      <br />
      <PerfectScrollBar>
        <DataTable
          columns={[
            {
              title: "Descricao",
              field: "descricao",
              cellStyle: { textAlign: "center" },
            },
            {
              title: `${
                permissions?.includes(15) ? "Arquivos Digitalizados" : ""
              }`,
              cellStyle: { textAlign: "center" },
              render: (row) => {
                return (
                  <div>
                    {permissions?.includes(15) && (
                      <button
                        style={{
                          border: "1px solid #000041",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                          borderRadius: "15px",
                          padding: "5px 25px",
                        }}
                        onClick={() => setOpenListArquivos(row)}
                      >
                        Ver Arquivos
                      </button>
                    )}
                  </div>
                );
              },
            },
            {
              title: `${
                permissions?.includes(12) ? "Digitalizar Arquivos" : ""
              }`,
              field: "funcionario",
              cellStyle: { textAlign: "center" },
              render: (row) => {
                return (
                  <div>
                    {permissions?.includes(12) && (
                      <button
                        style={{
                          border: "1px solid #000041",
                          backgroundColor: "transparent",
                          cursor: "pointer",
                          borderRadius: "15px",
                          padding: "5px 25px",
                        }}
                        onClick={() => SelectFolderToUpload(row)}
                      >
                        Enviar Arquivo
                      </button>
                    )}
                  </div>
                );
              },
            },
            // {
            //   title: "Arquivos Pendente Envio",
            //   field: "funcionario",
            //   cellStyle: { textAlign: "center" },
            // },
          ]}
          data={pastaFuncionario}
          options={{
            headerStyle: {
              backgroundColor: "#F2F2F3",
              fontSize: 12,
              textAlign: "center",
              borderLeft: "solid 0.5px white",
            },
            rowStyle: (rowData) => ({
              fontSize: 14,
              textAlign: "center",
            }),
          }}
          // detailPanel={[
          //   (row) => ({
          //     render: (row) => {
          //       if (row) {
          //         return (
          //           <div style={{ padding: "1em" }}>
          //             <Grid container spacing={1} alignItems="center">
          //               <Grid item xs={12} sm={3}>
          //                 <MdOutlineDocumentScanner size="15" color="#0e92a0" />
          //                 <b
          //                   style={{
          //                     marginLeft: "2em",
          //                     color: "#474747",
          //                     fontSize: "12px",
          //                   }}
          //                 >
          //                   DOC. DIGITALIZADOS:{" "}
          //                 </b>
          //                 {/* {row.pastas.map((item) => {
          //                       return (
          //                         <div
          //                           style={{
          //                             marginTop: "1.2em",
          //                             marginLeft: "1em",
          //                             color: "#474747",
          //                             display: "flex",
          //                             flexDirection: "row",
          //                           }}
          //                         >
          //                           <button
          //                             onClick={() => setOpenListArquivos(item)}
          //                             style={{
          //                               cursor: "pointer",
          //                               background: "transparent",
          //                               border: "none",
          //                               marginBottom: "-0.3em",
          //                             }}
          //                           >
          //                            
          //                             <BsCardImage color="#474747" /> &nbsp;
          //                             Visualizar
          //                           </button>
          //                         </div>
          //                       );
          //                     })} */}
          //               </Grid>

          //               <Grid item xs={12} sm={3}>
          //                 <BsUpcScan size="15" color="#0e92a0" />
          //                 <b
          //                   style={{
          //                     marginLeft: "2em",
          //                     color: "#474747",
          //                     fontSize: "12px",
          //                   }}
          //                 >
          //                   DIGITALIZAR ARQUIVO:{" "}
          //                 </b>
          //               </Grid>

          //               <Grid item xs={12} sm={3}>
          //                 <PieChart size="15" color="#0e92a0" />
          //                 <b
          //                   style={{
          //                     marginLeft: "2em",
          //                     color: "#474747",
          //                     fontSize: "12px",
          //                   }}
          //                 >
          //                   QUANTIDADE DE ARQUIVOS:{" "}
          //                 </b>
          //                 0
          //               </Grid>
          //             </Grid>
          //           </div>
          //         );
          //       }
          //     },
          //   }),
          // ]}
        />
      </PerfectScrollBar>

      <ModalArquivosDigitalizados
        open={openModalArquivos}
        arquivos={arquivos}
        idLista={listaDeArquivos}
        closeModal={() => setOpenModalArquivos(false)}
        permissions={permissions}
      />
      <ModalDigitalizarArquivo
        openModal={openModalUploadArquivo}
        closeModal={() => setOpenModalUploadArquivo(false)}
        pasta={useFolderUpload}
        tipo_de_arquivos={listaPorCategoria}
      />

      <ModalCriarPasta
        open={openModalCriarPasta}
        categorias={categoria_disponivel}
        setClose={() => setOpenModalCriarPasta(false)}
        collection={funcionarioVinculado[0]?.id}
        idFuncionario={idFuncionario}
      />
    </div>
  );
}

export default OpenFolderEmployee;
