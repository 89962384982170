

import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import theme from "./theme/index";
import { ThemeProvider } from "@material-ui/core";
import {responseInterceptor, requestInterceptor } from './services/interceptor';

import store from "./store";


const browserHistory = createBrowserHistory();

responseInterceptor(store)
requestInterceptor(store, browserHistory)

const App = () => {
  const routing = useRoutes(routes);
  return (
   <Provider store={store}>    
      <ThemeProvider theme={theme}> {routing} </ThemeProvider>
  </Provider>
  )
};

export default App;