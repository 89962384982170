import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { BiArchive } from "react-icons/bi";
import { BsBuilding, BsPeople } from "react-icons/bs";
import { HiOutlineDatabase } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { loadHome } from "../../store/actions/home";
import { Page } from "./../../components/index";
import InsertLegenda from './components/InsertLegenda';
import PieChartsComponents from "./components/PieChartsComponents";
import useStyles from "./styles";

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { home} = useSelector((state) => state.home);

console.log(home)
  const [value, onChange] = useState(new Date());

  const dados = [
    {
      name: `Ativos ${home.count_arquivos_ativos}`, 
      value: home.count_arquivos_ativos,
    },
    {
      name: "Inativos",
      value: 0,
    },
  ];

  
  useEffect(() => {
    dispatch(loadHome());
  }, []);

  return (
    <>
      <Page title="Ged - Relatórios" style={{ paddingTop: "16px" }}>
        GED - Support | <b>Relatório Simplificado</b>
        <div className={classes.home}>
          <Grid container spacing={2} style={{ marginTop: "2em" }}>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className={classes.cardContainer}>
                    Quantidade de arquivos enviados
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={2}>
                        <div
                          className={classes.icone}
                          style={{ background: "linear-gradient(90deg, #FFA500 17%, #ff5000 93%)" }}
                        >
                         
                          <BiArchive size={25} color="#fff"  style={{marginTop: '1em'}}/>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <h3>{home.count_arquivos}</h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.cardContainer}>
                    Ocupação armazenamento
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={2}>
                        <div
                          className={classes.icone}
                          style={{ background: "linear-gradient(90deg, #800080 17%, #46007f 93%)"}}
                        >
                         
                          <HiOutlineDatabase size={25} color="#fff" style={{marginTop: '1em'}} />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <h3>{home.sum_storage ? home.sum_storage.toFixed(2) : home.sum_storage }mb</h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.cardContainer}>
                    Quantidade de funcionários
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={2}>
                        <div
                          className={classes.icone}
                          style={{ background: "linear-gradient(90deg, #000041 17%, #002a6d 93%)"}}
                        >
                          <BsPeople size={25} color="#fff" style={{marginTop: '1em'}} />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <h3>{home.count_funcionarios}</h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.cardContainer}>
                    Quantidade de empresas
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={2}>
                        <div
                          className={classes.icone}
                          style={{ background: "linear-gradient(90deg, #008000 17%, #349b01 93%)" }}
                        >
                          <BsBuilding size={25} color="#fff" style={{marginTop: '1em'}} />{" "}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <h3>{home.count_company}</h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>
              <div className={classes.cardContainer}>
              
            <div style={{ marginTop: '-3em'}}>
            Status dos Arquivos
                  <PieChartsComponents
                    titulo="Arquivos Ativos e Inativos"
                    data={dados}
                  />
                   <InsertLegenda
                    data={dados}
                  />
                  </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Page>
    </>
  );
}

export default Home;
