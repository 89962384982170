import {
    Grid, Table,
    TableBody,
    TableCell,
    TableContainer, TableHead, TableRow, TextField
} from "@material-ui/core";
import faker from "faker-br";
import React, { useEffect, useState } from "react";
import { Save, XCircle } from "react-feather";
import { Link } from 'react-router-dom';
import Modal from "./../../../components/modal/index";
import useStyles from "./styles";

function Grupos() {
  const classes = useStyles();

  const [dados, setDados] = useState([]);

  const generate = () => {
    let item = [];
    for (let i = 0; i < 1; i++) {
      item.push(
        {
          id: faker.random.number(),
          name: "Support Rh",
        },
        {
          id: faker.random.number(),
          name: "Usina Carolo",
        }
      );
      setDados(item);
    }
    item.sort((a, b) => (a.nome_razao < b.nome_razao ? -1 : 1));
  };

  useEffect(() => {
    generate();
  }, []);

  const [open, setOpen] = useState(false);

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  return (
    <>
      <div className={classes.prontuarios}>
        <div className={classes.prontuariosContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className={classes.center}>
                <h1>
                  Lista de Grupos: <b>2</b>
                </h1>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.center}>
                <button
                  className={classes.btnEdit}
                  style={{ marginTop: "1em" }}
                >
                 
                  Adicionar
                </button>
              </div>
            </Grid>
          </Grid>

          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dados.map((item) => {
                  return (
                    <>
                      <TableRow key={item.id}>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          <button
                            className={classes.btnEdit}
                            onClick={() => Open()}
                          >
                           
                            Editar
                          </button>
                          {"   "}
                          <Link to="/app/operador/grupos/view">
                          <button
                            className={classes.btnEdit}
                            style={{ backgroundColor: "Green" }}
                          >
                           
                            Vizualizar
                          </button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            style={{ padding: "20px 30px", width: '40%' }}
            title="Editar Cliente"
            subTitle="Edite os dados do cadastro"
            buttons={[
              {
                title: "Cancelar",
                icon: <XCircle height="1.5em" width="1.5em" />,
                style: { color: "red", fontSize: "14px" },
                onClick: () => Close(),
              },
              {
                title: "Editar",
                icon: <Save height="1.5em" width="1.5em" />,
                style: { color: "green", fontSize: "14px" },
                onClick: () => console.log("Salvou"),
              },
            ]}
          >
          <br/>
            <TextField
              fullWidth
              label="Razão Social/Nome"
              id="fullWidth"
              value="Support Empresarial"
              margin="dense"
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Descrição"
              margin="dense"
              variant="outlined"
            />
               <br/>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Grupos;
