import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Button,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import faker from "faker-br";
import Modal from "./../../../../../components/modal/index";
import { Search, ArrowLeft, UserPlus, ArrowLeftCircle } from "react-feather";

function UserGroups() {
  const classes = useStyles();

  const [dados, setDados] = useState([]);

  const [open, setOpen] = useState(false);

  const Open = () => {
    setOpen(open ? false : true);
  };
  const Close = () => {
    setOpen(open === false);
  };

  const generate = () => {
    let item = [];
    for (let i = 0; i < 1; i++) {
      item.push({
        id: faker.random.number(),
        nome: faker.random.number(),
        cnpj: faker.random.number(),
        acao: faker.random.number(),
      });
      setDados(item);
    }
    item.sort((a, b) => (a.nome_razao < b.nome_razao ? -1 : 1));
  };

  useEffect(() => {
    generate();
  }, []);

  return (
    <>
      <div className={classes.prontuarios}>
        <div className={classes.prontuariosContainer}>
        <Link to="/app/operador/grupos/view">
        <ArrowLeft style={{color: 'grey'}} />
        </Link>
          <div className={classes.center}>
            <h1>Empresa</h1>
          </div>
          <Divider />
          <br />
          <b>Lista de Contatos Empresa:</b>: Matriz
          <br />
          <br />
          <br />
          <Divider />
          <br />
          Listando: <b>0</b>
          <div style={{ float: "right" }}>
            &nbsp;
            <Button
              style={{
                backgroundColor: "#1DA7A4",
                color: "#fff",
              }}
              onClick={() => Open()}
            >
              + Adicionar
            </Button>
          </div>
          <br />
          <br />
          <br />
          <br />
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Ativos</TableCell>
                  <TableCell>Inativos</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dados.map((item) => {
                  return (
                    <>
                      <TableRow key={item.id}>
                        <TableCell>
                          <b>Geral</b>
                        </TableCell>
                        <TableCell>{item.id}</TableCell>
                        <TableCell>{item.nome}</TableCell>
                        <TableCell>{item.cnpj}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            style={{ padding: "20px 30px", width: "40%" }}
            title="Adicionar Usuário"
            buttons={[
              {
                title: "Voltar",
                icon: <ArrowLeftCircle height="1.5em" width="1.5em" />,
                style: { color: "red", fontSize: "14px" },
                onClick: () => Close("Salvou"),
              },
              {
                title: "Adicionar",
                icon: <UserPlus height="1.5em" width="1.5em" />,
                style: { color: "green", fontSize: "14px" },
                onClick: () => Close("Salvou"),
              },
            ]}
          >
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Nome: "
                  id="fullWidth"
                  margin="dense"
                  variant="outlined"
                />{" "}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="CPF:"
                  id="fullWidth"
                  margin="dense"
                  variant="outlined"
                />{" "}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  margin="dense"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Senha"
                  margin="dense"
                  variant="outlined"
                  type="password"
                />
              </Grid>
            </Grid>
            <br />
          </Modal>
        </div>
      </div>
    </>
  );
}

export default UserGroups;
