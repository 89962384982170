
export const permissoes = [
    {
      nome: "Cadastro de Arquivos",
      id: 2,
    },
    {
      nome: "Cadastro de Funcionários",
      id: 7,
    },
    {
      nome: "Cadastro de Empresas",
      id: 9,
    },
    {
      nome: "Tipos de Arquivos",
      id: 10,
    },
    {
      nome: "Vincular Funcionário e Empresa",
      id: 11,
    },
    {
      nome: "Cadastro de Usuários Plataforma",
      id: 8,
    },
    {
      nome: "Permissão Upload de Arquivo",
      id: 12,
    },
    {
      nome: "Permissão Excluir Arquivo",
      id: 13,
    },
    {
      nome: "Permissão Criar Pasta Arquivos/Funcionários",
      id: 14,
    },
    {
      nome: "Permissão Baixar Arquivos",
      id: 15,
    },
    {
      nome: "Permissão Criar Pasta Arquivos/Funcionários",
      id: 16,
    },
  ];