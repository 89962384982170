import { Grid } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { RiDonutChartFill } from 'react-icons/ri';
 
const useStyles = makeStyles({
  square: {
    width: 20,
    height: 20,
    position: "relative",
    top: "4px",
  },
//   text: {
//     marginLeft: 0,
//     marginRight: 20,
//   },
});

const COLORS = [ '#11d6a8', '#e5125f'];

const InsertLegenda = (props) => {
  const classes = useStyles();

  const data = props.data;

  return (
    <div>
      <Grid container spacing={2}>
        {data.map((row, index) => (
                 <Grid item xs={12} sm={6}>
            <span>
             
              <RiDonutChartFill
                className={classes.square}
                style={{ color: COLORS[index % COLORS.length] }}
              />{" "}
            </span>{" "}
            <span className={classes.text}> {row.name} </span>
            </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default InsertLegenda;
