import queryString from "query-string";
import store from "../index";
import http from "./../../services/api";
import * as types from "./../types/tipos_de_arquivos";

export const loadTipodeArquivo = filtro => {
  return dispatch => {
    dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: true });
    http.get(`/api/v1/tipos_de_arquivo/list`).then(
      response => {
        const data = response.data   
        dispatch([
          { type: types.SET_TIPO_ARQUIVO, payload: data},

          { type: types.LOADING_TIPO_ARQUIVO, payload: false }
        ]);
      },
      error => {
        console.log(error);
        dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: false });
      }
    );
  };
};

export function ListarTiposPorCategoria(id) {
  const qry = queryString.stringify(id, { arrayFormat: 'index' });
  return (dispatch) => {
    dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: true });
    http.get(`/api/v1/tipos_de_arquivo/list?${qry}`).then(
      (response) => {
        const data = response.data;

        console.log('Listagem de arquivos', data)
        dispatch([
          { type: types.SET_LISTA_POR_CATEGORIA, payload: data },
          { type: types.LOADING_TIPO_ARQUIVO, payload: false },
        ]);
      },
      (error) => {
        console.log(error);
        dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: false });
      }
    );
  };
}

export function inserirTipodeArquivo(tipos_de_arquivo ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: true });
    http.post(`/api/v1/tipos_de_arquivo/insert`, tipos_de_arquivo)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: false });
          reject();
        }
      );
  });
}

export function editarTipodeArquivo(categoria, id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: true });
    http.post(`/api/v1/tipos_de_arquivo/edit/${id}`, categoria)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: false });
          reject();
        }
      );
  });
}

export function disabledFuncionario(id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: true });
    http.put(`/api/v1/funcionarios/disable/${id}`,)
      .then(
        ({ data }) => {
          
          store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: false });
          resolve();
        },
        (e) => {
          store.dispatch({ type: types.LOADING_TIPO_ARQUIVO, payload: false });
          reject();
        }
      );
  });
}

