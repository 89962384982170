import { createMuiTheme } from "@material-ui/core";

import overrides from './overrides';
import palette from './pallete';

const theme = createMuiTheme(
  {
    palette,
    // shadows,
    // typography,
    overrides: overrides,
    zIndex: {
      appBar: 2200,
      drawer: 2100
    }
  }
);

export default theme;