import {
  Button, IconButton, InputAdornment, TextField
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import icone from "../../assets/images/support_logo.png";
import { autenticarUsuario } from "../../store/actions/usuario";

import { Box, Formulario, Home } from "./styles";

function Login() {
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);

  function ShowPassword() {
    setShowPass(showPass ? false : true);
  }

  const navigate = useNavigate();

  const [data, setData] = useState({ email: "", password: "" });

  async function sendLogin(event) {
    event.preventDefault();
    try {
      await autenticarUsuario(data.email, data.password);
      console.log("Login ok");
      navigate("/app/home", { replace: true });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Home>
      <Box>
        <div style={{ textAlign: "center", padding: "1em" }}>
          <img src={icone} style={{ width: "150px", marginTop: 10 }} />
        </div>
        <h4 style={{ textAlign: "center" }}>Área do Cliente</h4>

        <Formulario onSubmit={sendLogin}>
          <label>
            <div>
              <TextField
                type="email"
                value={data.email}
                fullWidth
                InputLabelProps={{ shrink: true }}
                margin="dense"
                onChange={(event) =>
                  setData({
                    ...data,
                    email: event.target.value,
                  })
                }
                id="email"
                label="E-mail"
              />
            </div>
            <div>
              <TextField
                type={showPass ? "text" : "password"}
                value={data.password}
                onChange={(event) =>
                  setData({
                    ...data,
                    password: event.target.value,
                  })
                }
                id="password"
                label="Senha"
                fullWidth
                InputLabelProps={{ shrink: true }}
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ cursor: "Pointer", opacity: "0.7" }}
                      onClick={ShowPassword}
                    >
                      <IconButton onClick={ShowPassword}>
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </label>
          <br />
          <div>
            <Button variant="outlined" fullWidth type="submit" color="primary">
              Entrar
            </Button>
          </div>
          <br />
        </Formulario>
        <div style={{ paddingBottom: "2em", textAlign: "center" }}>
          <Link to="/esqueci">esqueci minha senha</Link>

        </div>
      
        <br />
      </Box>
    </Home>
  );
}

export default Login;
