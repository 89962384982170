export const LOAD_FUNCIONARIO = 'LOAD_FUNCIONARIO';
export const LOAD_FUNCIONARIOS = 'LOAD_FUNCIONARIOS';
export const LOAD_FUNCIONARIOS_PAGE = 'LOAD_FUNCIONARIOS_PAGE';
export const LOAD_FUNCIONARIOS_PERPAGE = 'LOAD_FUNCIONARIOS_PERPAGE';
export const LOAD_FUNCIONARIOS_TOTAL = 'LOAD_FUNCIONARIOS_TOTAL';
export const LOAD_FILTRO = 'LOAD_FILTRO';
export const LOAD_PLANOS = 'LOAD_PLANOS';
export const LOADING_FUNCIONARIOS = 'LOADING_FUNCIONARIOS';
export const FUNCIONARIO_ADD = 'FUNCIONARIO_ADD';
export const FUNCIONARIO_EDIT = 'FUNCIONARIO_EDIT';
export const ERROR = 'ERROR';