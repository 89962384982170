import styled from "@emotion/styled";
import bg from '../../assets/images/background.jpg';

export const Home = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: cover;
background-image: url(${bg}); 
`;

export const Box = styled.div`
  width: 500px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 12px 2px #e5e0e0;
  @media (max-width: 768px) {
    width: 80%;
  }
`;


export const Formulario = styled.form`
  width: 80%;
  margin: auto;
  text-align: center;
`;
