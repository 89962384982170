import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  user:{
    width: '100%',
    height: '100vh'
  },
  addUser:{
    width: '95%',
    padding: '1em',
    margin: 'auto',
    textAlign: 'center',
    marginTop: '1em',
    boxShadow: '0px 0px 15px 0px #e3e3e3',
    '& h1':{
      fontSize: '16px',
    },
  },
  centerbtn:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  btnUpload:{
    
    '& button':{
      
      border: 'none',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: "#24A0D5",
      color: '#fff',
      cursor: 'pointer',
      borderRadius: '5px'
    }
  },
  btnDownload:{
    textAlign: 'center',
    '& button':{
      border: 'none',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: "#e8ab1e",
      color: '#fff',
      cursor: 'pointer',
      borderRadius: '5px'
    }
  },
  btnAcesso:{
    textAlign: 'center',
    '& button':{
      border: 'none',
      padding: '5px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: "#16A891",
      color: '#fff',
      cursor: 'pointer',
      borderRadius: '5px'
    }
  }
}));

export default useStyles;