import http from './api';

const getToken = () => localStorage.getItem('token');

const setErrorHandle = (error, store) => {
  const errorHandle = {};
  // if (error.response === undefined) {
  //   errorHandle.message = error.message;
  //   errorHandle.modal = false;
  //   errorHandle.type = 'Error';
  //   errorHandle.trace = error.stack;
  //   errorHandle.url = error.config.url;
  //   store.dispatch({ type: 'SET_ERROR', payload: errorHandle });
  // } else if (error.response.data !== undefined && error.response.data !== '') {
  //   errorHandle.message = `${error.request.status} - ${error.request.statusText}`;
  //   errorHandle.modal = true;
  //   errorHandle.type = 'Error';
  //   errorHandle.trace =
  //     typeof error.response.data == 'object'
  //       ? JSON.stringify(error.response.data)
  //       : error.response.data;
  //   errorHandle.url = error.request.responseURL;
  //   store.dispatch({ type: 'SET_ERROR', payload: errorHandle });
  // } else {
  //   errorHandle.message = `Erro desconhecido`;
  //   errorHandle.modal = true;
  //   errorHandle.type = 'Error';
  //   errorHandle.trace =
  //     typeof error.response == 'object'
  //       ? JSON.stringify(error.response)
  //       : error.response;
  //   errorHandle.url = error.request.responseURL;
  //   store.dispatch({ type: 'SET_ERROR', payload: errorHandle });
  // }
};

const responseInterceptor = store => {
  http.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      setErrorHandle(error, store);
      return Promise.reject(error);
    }
  );
};

const requestInterceptor = (store, navigate) => {
  http.interceptors.request.use(config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;

    if (
      config.url !== '/api/v1/auth/autenticar' 
    ) {
      const { usuario } = store.getState().usuario;
      const tipo = localStorage.getItem('usuario');    
    }
    return config;
  });
};

export { responseInterceptor, requestInterceptor };
