import React from 'react'
import { TextField } from "@material-ui/core";

function InputSelect({ name, label, value, values, onChange, disabled=false }) {
  return (
    <TextField
      select
      disabled={disabled}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      style={{ width: "100%" }}
      SelectProps={{
        native: true,
      }}
      InputLabelProps={{ shrink: true }}
      margin="dense"
      variant="outlined"
    >
      {values?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
}

export default InputSelect;