import React from "react";
import dayjs from "dayjs";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DayjsUtils from '@date-io/dayjs';

function InputDate({ label, value, onChange, disabled = false, fullWidth = false, error, helperText, ...props }) {
  const handleDateChange = (value) => {
    onChange(dayjs(value).format("YYYY-MM-DD"));
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardDatePicker
        {...props}
        disabled={disabled}
        label={label}
        value={value}
        onChange={handleDateChange}
        format="DD/MM/YYYY"
        margin="dense"
        fullWidth={fullWidth}
        orientation="landscape"
        clearLabel="Limpar"
        clearable={true}
        cancelLabel="Cancelar"
        showTodayButton={true}
        todayLabel="Hoje"
        autoOk={true}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          margin: "dense",
        }}
        inputVariant="outlined"
        error={error}
        invalidDateMessage={helperText || 'Data inválida'}
      />
    </MuiPickersUtilsProvider>
  );
}

export default InputDate;