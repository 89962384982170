import * as types from './../types/usuario';

const INITIAL_STATE = {
  loading: false,
  usuario: { },
  usuarios: [{}],
  page: 1,
  perpage: 10,
  total: 0,
  usuario_permissao: {},
  dados_pessoais: {
    nome: "",
    email: "",
    password: "",
    permissoes: ""
  },
  loadResetSenha: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USUARIOS:
      return { ...state, usuario: action.payload };
    case types.LOADING_RESET_SENHA:
      return { ...state, loadResetSenha: action.payload };
    case types.SET_USUARIOS_LIST:
      return { ...state, usuarios: action.payload };
    case types.LOAD_USUARIOS_PAGE:
      return { ...state, page: action.payload };
    case types.LOAD_USUARIOS_PERMISSAO:
      return { ...state, page: action.payload };
    case types.LOAD_USUARIOS_PERPAGE:
      return { ...state, perpage: action.payload };
    case types.LOAD_USUARIOS_TOTAL:
      return { ...state, total: action.payload };
    case types.LOADING_USUARIOS:
      return { ...state, loading: action.payload };
      case types.DADOS_PESSOAIS_SET:
        return { ...state, dados_pessoais: { ...state.dados_pessoais, ...action.payload } };
    default:
      return state;
  }
};