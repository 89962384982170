import React, { useState } from "react";
import useStyles from "./styles";

import InputType from "../../components/InputTypes/InputType";

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
    AttachMoney,
} from "@material-ui/icons";

function Contabil() {
  const classes = useStyles();

  return (
    <div className={classes.user}>
      <div className={classes.addUser}>
          <br/>
        Arquivos: Contabil
        <br/>
        <br/>
            <InputType
            xs={12}
            sm={6}
              title={[
                {  link: "/app", nome: "Balancete", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                {  link: "/app", nome: "Lançamentos contábeis de contribuições previdenciárias – Livro Diário – Livro Razão", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                {  link: "/app", nome: "Comprovante de Rendimentos Pagos e de Retenção na Fonte", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                { link: "/app", nome: "Comprovantes da Escrituração (Notas Fiscais e recibos)", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                { link: "/app", nome: "Contrato de Seguros – informação de valores", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                {  link: "/app", nome: "Declaração de Ajuste Anual – IR Pessoa Física e comprovantes de deduções e outros valores", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                {  link: "/app", nome: "DIRF – Declaração de imposto de Renda Retido na Fonte", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                {  link: "/app", nome: "Declaração de Débitos e Créditos Tributários Federais – DCTF", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                {  link: "/app", nome: "Demonstrativo de Notas Fiscais – DNF", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                {  link: "/app", nome: "Imposto sobre Produtos Industrializados (pessoa jurídica) – comprovantes de escrituração", icon: <  AttachMoney style={{fontSize: '12px'}} /> },
                {  link: "/app", nome: "Nota Fiscal – Fatura de Serviço", icon: <  AttachMoney style={{fontSize: '12px'}} /> }  ,  
                {  link: "/app", nome: "Livro de Registro de Entradas", icon: <  AttachMoney style={{fontSize: '12px'}} /> }    ,
                {  link: "/app", nome: "Livro de Registro de Saídas", icon: <  AttachMoney style={{fontSize: '12px'}} /> }    
            ]}      
            />
        {/* <Grid item xs={12} sm={3}> */}






      </div>
    </div>
  );
}

export default Contabil;
